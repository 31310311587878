import { DEEPTALK_API_HOST } from "../constants";
import api from "../utils/ApiKit";

export const getMetadataSummaryReq = (modelId: number) => {
  return api
    .getInstance()
    .get<{ summary: metadataSummaryElement[] }>(
      `${DEEPTALK_API_HOST}/models/${modelId}/metadata_summary/`
    );
};

export type metadataSummaryElement =
  | categoricalMetadataSummaryElement
  | numericalMetadataSummaryElement;

export type categoricalMetadataSummaryElement = baseMetadataSummaryElement & {
  selected_metadata_type: "categorical";
  summary: {
    value_counts: { [category_name: string]: number };
  };
};
export type numericalMetadataSummaryElement = baseMetadataSummaryElement & {
  selected_metadata_type: "numerical";
  summary: {
    min_value: number;
    max_value: number;
  };
};

export type baseMetadataSummaryElement = {
  original_column_name: string;
  deeptalk_column_name: string;
  selected_metadata_class: string;
  dynamic_labeling: DynamicMetadataLabeling;
};

export type DynamicMetadataLabeling = {
  column_label?: string;
  value_labels?: { [key: string]: string };
  hide?: boolean;
};

// export type ValueCount = { category_name: string; value_count: number };
