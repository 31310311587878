import { SentimentLimits } from "../reducers/model_results";
import { ID, ISODate } from "./common.interfaces";
import { ProjectFile } from "./projects.interfaces";

export enum ModelType {
  TopicDetection = "TD",
  CustomDetection = "CD",
  MultilabelClassifier = "MC",
  TextClustering = "TC",
  Classification = "CL",
  DeeperText = "DET",
  VoiceOfEmployee = "VoE",
  KnowledgeBase = "KB",
}

export enum ModelStatus { // todo: add all statuses
  Pending = "pending",
  Validating = "validating",
  Validated = "validated",
  CategoriesAdded = "categories.added",
  UserExamplesAdded = "user_examples.added",
  ExamplesExtracted = "examples.extracted",
  ExamplesAnnotated = "examples.annotated",
  Initialized = "initialized",
  Training = "training",
  Success = "success",
  DataCollection = "data-collection",
  TrainingDenied = "training.denied",
  Error = "error",
  ValidationError = "validation.error",
  TrainingError = "training.error",
  ConfirmationRequired = "confirmation.needed",
}

export type ModelExamples = { [category: string]: string[] };

export type ModelMetaData = {
  conversations: number;
  max_date: ISODate;
  min_date: ISODate;
  person_types: string[];
  rows: number;
  categories?: string[];
  user_examples?: ModelExamples;
  collected_examples?: ModelExamples;
  annotated_examples?: ModelExamples;
  provider?: string;
  efs_embeddings_folder?: string;
  efs_data_folder?: string;
};

export type ModelValueMapping = {
  agent: string;
  client: string;
  bot?: string;
  system?: string;
};

export type TopicDetectionColumnMapping = {
  model_type: ModelType.CustomDetection | ModelType.TopicDetection;
  column_mapping: {
    original_conversation_id: string;
    created_at: string;
    content: string;
    person_type: string;
    metadata_1: string;
    metadata_2: string;
    metadata_3: string;
    metadata_4: string;
    metadata_class_1: string;
    metadata_class_2: string;
    metadata_class_3: string;
    metadata_class_4: string;
  };
};

export type MultilabelClassifierColumnMapping = {
  model_type: ModelType.MultilabelClassifier;
  column_mapping: {
    original_conversation_id: string;
    created_at: string;
    content: string;
    person_type: string;
    target: string;
  };
};

export type TextClusteringColumnMapping = {
  model_type: ModelType.TextClustering;
  column_mapping: {
    content: string;
    created_at: string;
    tag: string;
    metadata_1: string;
    metadata_2: string;
    metadata_3: string;
    metadata_4: string;
    metadata_5: string;
    metadata_6: string;
    metadata_7: string;
    metadata_8: string;
    metadata_class_1: string;
    metadata_class_2: string;
    metadata_class_3: string;
    metadata_class_4: string;
    metadata_class_5: string;
    metadata_class_6: string;
    metadata_class_7: string;
    metadata_class_8: string;
  };
};

export type VoEColumnMapping = {
  model_type: ModelType.VoiceOfEmployee;
  column_mapping: {
    content: string;
    created_at: string;
    tag: string;
    metadata_1: string;
    metadata_2: string;
    metadata_3: string;
    metadata_4: string;
    metadata_class_1: string;
    metadata_class_2: string;
    metadata_class_3: string;
    metadata_class_4: string;
  };
};

export type ClassificationColumnMapping = {
  model_type: ModelType.Classification;
  column_mapping: {
    content: string;
    target: string;
  };
};

export type DeeperColumnMappingGeneralText = {
  model_type: ModelType.DeeperText;
  column_mapping: {
    content: string;
    created_at: string;
  };
};

export type KBColumnMapping = {
  model_type: ModelType.KnowledgeBase;
  column_mapping: {
    content: string;
    created_at: string;
  };
};

export type ModelTypeAndColumnMapping =
  | TopicDetectionColumnMapping
  | MultilabelClassifierColumnMapping
  | TextClusteringColumnMapping
  | ClassificationColumnMapping
  | DeeperColumnMappingGeneralText
  | VoEColumnMapping
  | KBColumnMapping;

export type ModelSettings = {
  sentiment: SentimentLimits;
};

export type ProjectTextModel = ModelTypeAndColumnMapping & {
  id: ID;
  name: string;
  model_type: ModelType;
  project_id?: string; // todo: check the actual format
  description?: string;
  status?: string;
  status_description?: {};
  created_at?: ISODate;
  created_by?: string; // todo: user id?
  uploaded_file?: ProjectFile;
  metadata?: ModelMetaData;
  value_mapping?: ModelValueMapping;
  validated_file_uri_s3?: string;
  is_searcheable?: boolean;
  settings: ModelSettings;
  lang?: string;
  industry?: string[];
  use_case?: string;
  content_type?: string;
  from_template?: boolean;
};

export type ProjectModelCreatePayload = Pick<
  ProjectTextModel,
  | "name"
  | "model_type"
  | "description"
  | "status"
  | "uploaded_file"
  | "metadata"
  | "industry"
  | "lang"
  | "use_case"
  | "content_type"
  | "from_template"
>;
export type ProjectModelCreateRes = ProjectTextModel;

export type SaveCategoriesPayload = string[];
export type SaveCategoryUserExamplesPayload = string[];

export type TopicGroupCreateReq = {
  name: string;
};

export type TopicGroup = {
  name: string;
  model_id: number;
  created_at: ISODate;
  id: string;
};

export type TopicGroupMembership = {
  id: string;
  topic_group: string;
  topic_group_name: string;
  topic_uuid: string;
  added_at: string;
  topic_name: { value: string };
  topic_data: { label: string; size: number };
};

export type CategorySentimentResponseItem = {
  category_name: string;
  n_negative_topic_segments?: number;
  n_neutral_topic_segments?: number;
  n_positive_topic_segments?: number;
};

export type CountOverTimeResponseItem = {
  date: string;
  count: number;
};

export type UserCountOverTimeResponseItem = {
  date: string;
  user_count: number;
};

export type CategoryTimeEvolutionResponseItem = {
  date: string;
  total: number;
} & { [category: string]: number };

export type DistributionResponseItem = {
  ant_count: number;
  metadata_value: number;
};

export type DistributionItem = {
  count: number;
  value: number;
};

export type MetricResponseItem = {
  "0.0": number;
  "1.0": number;
  "2.0": number;
  "3.0": number;
  "4.0": number;
  "5.0": number;
  "6.0": number;
  "7.0": number;
  "8.0": number;
  "9.0": number;
  "10.0": number;
};

export type SentimentsByMetadataResponseItem = {
  name: string;
  positive: number;
  neutral: number;
  negative: number;
  totoal: number;
};

export type CSATTimeItem = {
  date: string;
  "1.0": number;
  "2.0": number;
  "3.0": number;
  "4.0": number;
  "5.0": number;
};

export type NPSTimeItem = {
  date: string;
  "0.0": number;
  "1.0": number;
  "2.0": number;
  "3.0": number;
  "4.0": number;
  "5.0": number;
  "6.0": number;
  "7.0": number;
  "8.0": number;
  "9.0": number;
  "10.0": number;
};

export type MetricTimeEvolutionResponseItem = NPSTimeItem | CSATTimeItem;

export type MetricByCategoryResponseItem = {
  [category: string]: {
    value_counts: {
      "0.0": number;
      "1.0": number;
      "2.0": number;
      "3.0": number;
      "4.0": number;
      "5.0": number;
      "6.0": number;
      "7.0": number;
      "8.0": number;
      "9.0": number;
      "10.0": number;
    };
  };
};

export type MetricByMetadataResponse = {
  [metadata: string]: {
    "0.0": number;
    "1.0": number;
    "2.0": number;
    "3.0": number;
    "4.0": number;
    "5.0": number;
    "6.0": number;
    "7.0": number;
    "8.0": number;
    "9.0": number;
    "10.0": number;
  };
};

export type MetricTimeDataByMetadataResponse = {
  [metadata: string]: {
    [date: string]: {
      "0.0": number;
      "1.0": number;
      "2.0": number;
      "3.0": number;
      "4.0": number;
      "5.0": number;
      "6.0": number;
      "7.0": number;
      "8.0": number;
      "9.0": number;
      "10.0": number;
    };
  };
};

export type MetricBreakdown = {
  indexDetail: string;
  potentialDetail: string;
  contributionDetail: string;
  posDetail: string;
  negDetail: string;
  neuDetail: string;
  totDetail: string;
  items: {
    label: string;
    index: number;
    potential: number;
    contribution: number;
    n_positive: number;
    n_negative: number;
    n_neutral: number;
    n_total: number;
  }[];
};

export type SentimentDistributionResponseItem = {
  n_negative_sentiments: number;
  n_neutral_sentiments: number;
  n_positive_sentiments: number;
};

export type CategoryByMetadataResponse = {
  result: CategoryByMetadataResponseItem[];
};

export type CategoryByMetadataResponseItem = {
  md_val: string;
  category_count: number;
  base_count: number;
};

export type MetadataFrequencyResponseItem = {
  metadata_value: string;
  ant_count: number;
};
