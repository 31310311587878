import { Observable, Store } from "redux";
import { EpicDependencies } from ".";
import { from, of } from "rxjs";
import { ActionsObservable, combineEpics, ofType } from "redux-observable";
import { catchError, map, mergeMap } from "rxjs/operators";
import {
  API_KEY_GENERATOR_HOST,
  APP_CREATE_API_KEY_ERROR,
  APP_CREATE_API_KEY_REQUEST,
  APP_CREATE_API_KEY_SUCCESS,
  APP_DELETE_API_KEY_ERROR,
  APP_DELETE_API_KEY_REQUEST,
  APP_DELETE_API_KEY_SUCCESS,
  APP_GET_API_KEY_ERROR,
  APP_GET_API_KEY_REQUEST,
  APP_GET_API_KEY_SUCCESS,
  APP_PATCH_API_KEY_ERROR,
  APP_PATCH_API_KEY_REQUEST,
  APP_PATCH_API_KEY_SUCCESS,
  APP_SET_API_KEY_ACTION,
} from "../constants";
import ApiKit from "../utils/ApiKit";
import { AxiosResponse } from "axios";

interface VoidRequest {
  (): Promise<AxiosResponse<any>>;
}

const getApiKeyRequest: VoidRequest = () => {
  return ApiKit.getInstance().get(`${API_KEY_GENERATOR_HOST}`);
};

const createApiKeyRequest: VoidRequest = () => {
  return ApiKit.getInstance().post(`${API_KEY_GENERATOR_HOST}`);
};

const deleteApiKeyRequest: VoidRequest = () => {
  return ApiKit.getInstance().delete(`${API_KEY_GENERATOR_HOST}`);
};

const updateApiKeyRequest: VoidRequest = () => {
  return ApiKit.getInstance().put(`${API_KEY_GENERATOR_HOST}`);
};

const baseHandler = (
  action$: ActionsObservable<any>,
  incomingType: string,
  requestMethod: VoidRequest,
  successAction: string,
  errorAction: string
) => {
  return action$.pipe(
    ofType(incomingType),
    mergeMap(() =>
      from(requestMethod()).pipe(
        mergeMap((response) => [
          {
            type: APP_SET_API_KEY_ACTION,
            payload: response.data,
          },
          {
            type: successAction,
            payload: response.data,
          },
        ]),
        catchError((error) => {
          if (error.response.status === 404) {
            return of({
              type: APP_SET_API_KEY_ACTION,
              payload: {
                apikey: undefined,
              },
            });
          } else {
            return of({
              type: errorAction,
              payload: error.messagem,
            });
          }
        })
      )
    )
  );
};

const getApiKeyEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return baseHandler(
    action$,
    APP_GET_API_KEY_REQUEST,
    getApiKeyRequest,
    APP_GET_API_KEY_SUCCESS,
    APP_GET_API_KEY_ERROR
  );
};

const createApiKeyEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return baseHandler(
    action$,
    APP_CREATE_API_KEY_REQUEST,
    createApiKeyRequest,
    APP_CREATE_API_KEY_SUCCESS,
    APP_CREATE_API_KEY_ERROR
  );
};

const updateApiKeyEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return baseHandler(
    action$,
    APP_PATCH_API_KEY_REQUEST,
    updateApiKeyRequest,
    APP_PATCH_API_KEY_SUCCESS,
    APP_PATCH_API_KEY_ERROR
  );
};

const deleteApiKeyEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return baseHandler(
    action$,
    APP_DELETE_API_KEY_REQUEST,
    deleteApiKeyRequest,
    APP_DELETE_API_KEY_SUCCESS,
    APP_DELETE_API_KEY_ERROR
  );
};

const deleteApiKeySuccessEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return baseHandler(
    action$,
    APP_DELETE_API_KEY_SUCCESS,
    getApiKeyRequest,
    APP_CREATE_API_KEY_SUCCESS,
    APP_CREATE_API_KEY_ERROR
  );
};

export const apiKeyEpic = combineEpics(
  createApiKeyEpic,
  updateApiKeyEpic,
  deleteApiKeyEpic,
  getApiKeyEpic,
  deleteApiKeySuccessEpic
);
