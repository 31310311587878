/*
 *
 * Auth reducer
 *
 */
import { ProjectFile } from "../dal";

import {
  APP_GET_PROJECTS_SUCCESS_ACTION,
  APP_SET_ALL_MODELS_ACTION,
  APP_SET_CURRENT_MODEL_ACTION,
  APP_SET_CURRENT_PROJECT_FILES_ACTION,
  APP_SET_CURRENT_PROJECT_MODELS_ACTION,
  APP_SET_SELECTED_PROJECT_ACTION,
  APP_UNSET_ALL_MODELS_ACTION,
  APP_UNSET_CURRENT_MODEL_ACTION,
  APP_UNSET_SELECTED_PROJECT_ACTION,
  APP_WIZARD_UPDATE_ANALYSIS_BATCH_STATUS_ACTION,
  APP_WIZARD_UPDATE_MODEL_STATUS_ACTION,
} from "../constants";

export enum ProjectStatus {
  New = "new",
  InProgress = "in-progress",
  Ready = "ready",
  Finished = "finished",
}

type ProjectMeta = {
  num_conversations: number;
  score: number;
};

export type Project = {
  id: number;
  name: string;
  description: string;
  status: ProjectStatus;
  meta: ProjectMeta;
  models: ProjectModel[];
  files: ProjectFile[];
  created_at: string;
  created_by: string;
  account: string;
};

export type AnalysisBatch = {
  id: number;
  ml_model: number;
  model_name: string;
  project_id: number;
  model_type: string;
  name: string;
  description: string;
  status: string;
  status_description: { name: string; description: string; columns: string[] };
  created_at: string;
  created_by: string;
  metadata?: any;
  uploaded_file: ProjectFile;
  column_mapping: any;
  value_mapping: any;
  validated_file_uri_s3: string;
  industry?: string[];
  lang?: string;
  use_case?: string;
  data_type?: string;
};

export type ProjectModel = {
  id: number;
  name: string;
  project_id: number;
  model_type: string;
  description: string;
  status: "initialized" | "running" | "success";
  created_at: string;
  created_by: string;
  uploaded_file: ProjectFile;
  settings: {
    sentiment: {
      lower_limit: number;
      upper_limit: number;
    };
  };
};

export type AppProjectsState = {
  current?: Project;
  currentModel?: ProjectModel;
  list: Project[] | undefined;
  byId: { [index: string]: Project };
  models: ProjectModel[] | undefined;
};

export const initialState: AppProjectsState = {
  list: undefined,
  byId: {},
  models: undefined,
};

// type ProjectActions = ActionWithPayload<Project[]>

export const projectReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case APP_GET_PROJECTS_SUCCESS_ACTION: {
      const byId = action.payload.reduce((map: any, project: Project) => {
        map[project.id] = { ...project };
        return map;
      }, {});

      return {
        ...state,
        list: action.payload,
        byId,
      } as AppProjectsState;
    }

    case APP_SET_SELECTED_PROJECT_ACTION: {
      const project = action.payload;
      return {
        ...state,
        current: {
          ...state.current,
          ...project,
        },
      };
    }

    case APP_UNSET_SELECTED_PROJECT_ACTION: {
      return {
        ...state,
        current: undefined,
      };
    }

    case APP_SET_CURRENT_PROJECT_MODELS_ACTION: {
      const models = action.payload.models;
      // const current = state.current;
      return {
        ...state,
        current: {
          ...state.current,
          models: models,
        },
      };
    }

    case APP_SET_CURRENT_PROJECT_FILES_ACTION: {
      const files = action.payload.files;
      const current = state.current;
      return {
        ...state,
        current: {
          ...current,
          files: files,
        },
      };
    }

    case APP_SET_CURRENT_MODEL_ACTION: {
      const model = action.payload.model;
      return {
        ...state,
        currentModel: model,
      };
    }

    case APP_UNSET_CURRENT_MODEL_ACTION: {
      return {
        ...state,
        currentModel: null,
      };
    }

    // case APP_WIZARD_UPDATE_ANALYSIS_BATCH_STATUS_ACTION: {
    //   const current = state.current;
    //   return {
    //     ...state,
    //     current: {
    //       ...current,
    //       models: null,
    //     },
    //   };
    // }

    case APP_SET_ALL_MODELS_ACTION: {
      return {
        ...state,
        models: action.payload,
      };
    }

    case APP_UNSET_ALL_MODELS_ACTION: {
      return {
        ...state,
        models: undefined,
      };
    }

    default:
      return state;
  }
};
