import {
  AnyActionI,
  APP_WIZARD_CLOSE_HELP_WINDOW_ACTION,
  APP_WIZARD_CLOSE_MODAL_ACTION,
  APP_WIZARD_CREATE_MODEL_SUCCESS_ACTION,
  APP_WIZARD_GO_TO_STEP_ACTION,
  APP_WIZARD_NEXT_STEP_ACTION,
  APP_WIZARD_OPEN_AT_SPECIFIC_STATE,
  APP_WIZARD_OPEN_HELP_WINDOW_ACTION,
  APP_WIZARD_OPEN_MODAL_ACTION,
  APP_WIZARD_PREV_STEP_ACTION,
  APP_WIZARD_RETURN_TO_THE_LAST_STEP_ACTION,
  APP_WIZARD_SET_HELP_WINDOW_COMPONENT_KEY,
  APP_WIZARD_SET_MODEL_ACTION,
  APP_WIZARD_SET_MODEL_BASE_FIELDS_ACTION,
  APP_WIZARD_SET_MODEL_COLUMN_MAPPING_ACTION,
  APP_WIZARD_SET_MODEL_FILE_DATA_ACTION,
  APP_WIZARD_START_ACTION,
  APP_WIZARD_UPDATE_MODEL_STATUS_ACTION,
  APP_WIZARD_UPDATE_ANALYSIS_BATCH_STATUS_ACTION,
  APP_WIZARD_UPDATE_TYPE_ACTION,
  APP_WIZARD_SET_MODEL_USE_CASE_ACTION,
  APP_WIZARD_SET_MODEL_INDUSTRY_ACTION,
  APP_WIZARD_SET_MODEL_LANGUAGE_ACTION,
} from "../constants";
import {
  ModelStatus,
  ModelType,
  ProjectTextModel,
} from "../dal/model.interfaces";
import { ProjectFile } from "../dal/projects.interfaces";
import { AnalysisBatch } from "./project";

type Step = {
  name: WizardStepName;
  canGoBack: boolean;
  showInStepperFn: (state: State) => boolean;
  overrideStepperName?: WizardStepName;
  checkIfCurrentStepOnInitFn: (state: State) => boolean; // should return true if we want to stop on the step. false if step should be passed
};

type Modal = {
  modelType?: "text" | "DET" | "KB";
  modelTextSubtype?: "conversations" | "generalTextData" | "voe";
  model?: Partial<AnalysisBatch>;
  file?: ProjectFile;
};

export type WizardStepName =
  | "selectDataType"
  | "fileUpload"
  | "filePreview"
  | "columnMapping"
  | "validating"
  | "dataSummary"
  | "valueMapping"
  | "extra"
  | "finish"
  | "confirmation"
  | "error"
  | "success"
  | "data-collection"
  | "training-denied"
  | "training.denied"
  | "validation.error"
  | "training.error";

type State = {
  isStarted: boolean;
  isModalShown: boolean;
  isHelpWindowOpen: boolean;
  helpWindowTitle: string;
  helpWindowText: string;
  helpWindowComponentKey: string;
  projectId: number | null;
  currentStepName: WizardStepName;
  steps: Step[];
} & Modal;

const INITIAL_STEP_NAME: WizardStepName = "selectDataType";

const initalState: State = {
  isStarted: false,
  isModalShown: false,
  isHelpWindowOpen: false,
  helpWindowTitle: "",
  helpWindowText: "",
  projectId: null,
  currentStepName: INITIAL_STEP_NAME,
  modelType: undefined,
  modelTextSubtype: undefined,
  model: undefined,
  helpWindowComponentKey: "",
  steps: [
    {
      name: "selectDataType",
      canGoBack: false,
      showInStepperFn: (x) => true,
      checkIfCurrentStepOnInitFn: (x) => !x.model?.ml_model,
    },
    {
      name: "fileUpload",
      canGoBack: false,
      showInStepperFn: (x) => true,
      checkIfCurrentStepOnInitFn: (x) =>
        !x.model?.uploaded_file?.id &&
        x.model?.status !== ModelStatus.DataCollection &&
        x.model?.status !== ModelStatus.TrainingDenied,
    },
    {
      name: "filePreview",
      canGoBack: true,
      showInStepperFn: (x) => true,
      checkIfCurrentStepOnInitFn: (x) =>
        x.model?.status === ModelStatus.Pending,
    },
    {
      name: "columnMapping",
      canGoBack: true,
      showInStepperFn: (x) => true, // false if user account is premium
      checkIfCurrentStepOnInitFn: (x) => false,
    },
    {
      name: "validating",
      canGoBack: false,
      showInStepperFn: (x) => true,
      checkIfCurrentStepOnInitFn: (x) =>
        Boolean(
          x.model?.status === ModelStatus.Validating &&
            x.model?.metadata?.provider !== "intercom"
        ),
    },
    {
      name: "dataSummary",
      canGoBack: false,
      showInStepperFn: (x) => false,
      overrideStepperName: "validating",
      checkIfCurrentStepOnInitFn: (x) =>
        Boolean(
          x.model?.status === ModelStatus.Validated &&
            x.model?.value_mapping?.agent === undefined &&
            x.model?.metadata?.provider !== "intercom"
        ),
    },
    {
      name: "valueMapping",
      canGoBack: true,
      showInStepperFn: (x) => x.model?.model_type === ModelType.TopicDetection, // false if user account is premium
      checkIfCurrentStepOnInitFn: (x) => false,
    },
    {
      name: "extra",
      canGoBack: true,
      showInStepperFn: (x) => x.model?.model_type === ModelType.CustomDetection,
      checkIfCurrentStepOnInitFn: (x) =>
        x.model?.model_type === ModelType.CustomDetection &&
        (x.model?.status === ModelStatus.UserExamplesAdded ||
          x.model?.status === ModelStatus.CategoriesAdded ||
          x.model?.status === ModelStatus.ExamplesExtracted),
    },
    {
      name: "finish",
      canGoBack: false,
      showInStepperFn: (x) => true,
      checkIfCurrentStepOnInitFn: (x) =>
        x.model?.status === ModelStatus.Initialized ||
        x.model?.status === ModelStatus.Training,
    },
    {
      name: "confirmation",
      canGoBack: false,
      showInStepperFn: (x) => false,
      overrideStepperName: "finish",
      checkIfCurrentStepOnInitFn: (x) =>
        x.model?.status === ModelStatus.ConfirmationRequired ||
        Boolean(
          x.model?.status === ModelStatus.Validated &&
            x.model?.value_mapping?.agent !== undefined &&
            x.model?.metadata?.provider !== "intercom"
        ),
    },
    {
      name: "training.error",
      canGoBack: false,
      showInStepperFn: (x) => false,
      checkIfCurrentStepOnInitFn: (x) =>
        x.model?.status === ModelStatus.Error ||
        x.model?.status === ModelStatus.TrainingError,
    },
    {
      name: "validation.error",
      canGoBack: false,
      showInStepperFn: (x) => false,
      checkIfCurrentStepOnInitFn: (x) =>
        x.model?.status === ModelStatus.ValidationError,
    },
    {
      name: "success",
      canGoBack: false,
      showInStepperFn: (x) => false,
      checkIfCurrentStepOnInitFn: (x) =>
        x.model?.status === ModelStatus.Success,
    },
    {
      name: "data-collection",
      canGoBack: false,
      showInStepperFn: (x) => false,
      checkIfCurrentStepOnInitFn: (x) =>
        x.model?.status === ModelStatus.DataCollection ||
        (x.model?.status === ModelStatus.Validating &&
          x.model?.metadata?.provider === "intercom") ||
        (x.model?.status === ModelStatus.Validated &&
          x.model?.metadata?.provider === "intercom"),
    },
    {
      name: "training-denied",
      canGoBack: false,
      showInStepperFn: (x) => false,
      checkIfCurrentStepOnInitFn: (x) =>
        x.model?.status === ModelStatus.TrainingDenied,
    },
  ],
};

export const modelWizardReducer = (state = initalState, action: AnyActionI) => {
  switch (action.type) {
    case APP_WIZARD_START_ACTION:
      return {
        ...state,
        isStarted: true,
      } as State;

    case APP_WIZARD_RETURN_TO_THE_LAST_STEP_ACTION:
      const newState = {
        ...state,
        modelType: action.payload.batch.model_type ?? state.modelType,
        projectId: action.payload.projectId,
        model: action.payload.batch,
        isModalShown: true,
      } as State;

      let stepName = newState.steps[0].name;
      const lastStep = newState.steps.find((x) =>
        x.checkIfCurrentStepOnInitFn(newState)
      );
      if (lastStep) {
        stepName = lastStep.name;
      }
      newState.currentStepName = stepName;

      return newState;

    case APP_WIZARD_OPEN_MODAL_ACTION:
      return {
        ...state,
        isModalShown: true,
        projectId: action.payload.projectId,
        modelType: action.payload?.modelType,
      } as State;

    case APP_WIZARD_OPEN_AT_SPECIFIC_STATE:
      const tmpState = {
        ...state,
        projectId: action.payload.projectId,
        isModalShown: true,
        modelType: action.payload.modelType,
        modelTextSubtype: action.payload.modelTextSubType,
        isHelpWindowOpen: false,
        model: {
          ...state.model,
          ml_model: action.payload.modelId ?? state.model?.ml_model,
        },
      } as State;

      let tmpStepName = tmpState.steps[0].name;
      const tmpLastStep = tmpState.steps.find((x) =>
        x.checkIfCurrentStepOnInitFn(tmpState)
      );
      if (tmpLastStep) {
        tmpStepName = tmpLastStep.name;
      }
      tmpState.currentStepName = tmpStepName;
      return tmpState;

    case APP_WIZARD_CLOSE_MODAL_ACTION:
      return {
        ...state,
        isModalShown: false,
        projectId: null,
        model: undefined,
        modelType: undefined,
        modelTextSubtype: undefined,
        currentStepName: INITIAL_STEP_NAME,
        file: undefined,
        isHelpWindowOpen: false,
      } as State;

    case APP_WIZARD_GO_TO_STEP_ACTION: {
      return {
        ...state,
        currentStepName: action.payload,
      } as State;
    }

    case APP_WIZARD_NEXT_STEP_ACTION: {
      const stepIndex = state.steps.findIndex(
        (x) => x.name === state.currentStepName
      );
      const nextStepIndex =
        stepIndex >= 0 && state.steps[stepIndex + 1]
          ? stepIndex + 1
          : stepIndex;
      return {
        ...state,
        currentStepName: state.steps[nextStepIndex].name,
      } as State;
    }

    case APP_WIZARD_PREV_STEP_ACTION: {
      const stepIndex = state.steps.findIndex(
        (x) => x.name === state.currentStepName
      );
      const prevStepIndex =
        stepIndex >= 0 &&
        state.steps[stepIndex].canGoBack &&
        state.steps[stepIndex - 1]
          ? stepIndex - 1
          : stepIndex;
      return {
        ...state,
        currentStepName: state.steps[prevStepIndex].name,
      } as State;
    }
    /*
     * set model type for 1-3 steps
     */
    case APP_WIZARD_SET_MODEL_USE_CASE_ACTION:
      return {
        ...state,
        model: {
          ...state.model,
          use_case: action.payload.useCase,
        },
      } as State;

    case APP_WIZARD_SET_MODEL_INDUSTRY_ACTION:
      return {
        ...state,
        model: {
          ...state.model,
          industry: action.payload.industry,
        },
      } as State;

    case APP_WIZARD_SET_MODEL_LANGUAGE_ACTION:
      return {
        ...state,
        model: {
          ...state.model,
          lang: action.payload.lang,
        },
      } as State;

    case APP_WIZARD_UPDATE_TYPE_ACTION:
      return {
        ...state,
        ...action.payload,
        model: {
          ...state.model,
          ...(action.payload.model || {}),
        },
      } as State;

    case APP_WIZARD_UPDATE_ANALYSIS_BATCH_STATUS_ACTION: {
      if (action.payload.message.payload.object_id === state.model?.id) {
        return {
          ...state,
          model: {
            ...state.model,
            status: action.payload.message.payload.status,
            status_description:
              action.payload.message.payload.status_description,
          },
        };
      }
      return state;
    }

    case APP_WIZARD_SET_MODEL_BASE_FIELDS_ACTION:
      return {
        ...state,
        model: {
          ...state.model,
          ...action.payload,
        },
      } as State;

    case APP_WIZARD_CREATE_MODEL_SUCCESS_ACTION:
      return {
        ...state,
        model: {
          ...action.payload.model,
          ...{ ml_model: action.payload.model.id },
        },
      } as State;

    case APP_WIZARD_SET_MODEL_ACTION:
      return {
        ...state,
        model: {
          ...action.payload,
        },
      } as State;

    case APP_WIZARD_SET_MODEL_FILE_DATA_ACTION:
      return {
        ...state,
        file: action.payload,
      } as State;

    case APP_WIZARD_SET_MODEL_COLUMN_MAPPING_ACTION:
      return {
        ...state,
        model: {
          ...state.model,
          column_mapping: action.payload,
        },
      } as State;

    case APP_WIZARD_OPEN_HELP_WINDOW_ACTION:
      return {
        ...state,
        isHelpWindowOpen: true,
        helpWindowTitle: action.payload.title,
        helpWindowText: action.payload.text,
      };

    case APP_WIZARD_CLOSE_HELP_WINDOW_ACTION:
      return {
        ...state,
        isHelpWindowOpen: false,
        helpWindowTitle: "",
        helpWindowText: "",
      };

    case APP_WIZARD_SET_HELP_WINDOW_COMPONENT_KEY:
      return {
        ...state,
        helpWindowComponentKey: action.payload.key,
      };

    default:
      return state;
  }
};
