import { Action } from "redux";
import {
  ModelStatus,
  ModelTypeAndColumnMapping,
  ProjectFile,
  UUID,
} from "../dal";
import { ProjectTextModel } from "../dal/model.interfaces";
import { WizardStepName } from "../reducers/model-training-wizard";
import { CapacityState } from "../dal/settings";
import {
  APP_WIZARD_CREATE_MODEL_ACTION,
  APP_WIZARD_CLOSE_MODAL_ACTION,
  APP_WIZARD_GO_TO_STEP_ACTION,
  APP_WIZARD_NEXT_STEP_ACTION,
  APP_WIZARD_OPEN_MODAL_ACTION,
  APP_WIZARD_PREV_STEP_ACTION,
  APP_WIZARD_SET_MODEL_ACTION,
  APP_WIZARD_SET_MODEL_BASE_FIELDS_ACTION,
  APP_WIZARD_SET_MODEL_COLUMN_MAPPING_ACTION,
  APP_WIZARD_SET_MODEL_FILE_DATA_ACTION,
  APP_WIZARD_START_ACTION,
  APP_WIZARD_UPDATE_TYPE_ACTION,
  APP_WEBSOCKET_INIT_ACTION,
  APP_WEBSOCKET_MESSAGE_RECEIVED_ACTION,
  APP_WIZARD_RETURN_TO_THE_LAST_STEP_ACTION,
  APP_WIZARD_CREATE_MODEL_SUCCESS_ACTION,
  APP_WIZARD_OPEN_HELP_WINDOW_ACTION,
  APP_WIZARD_CLOSE_HELP_WINDOW_ACTION,
  APP_WIZARD_UPDATE_MODEL_STATUS_ACTION,
  APP_SET_CURRENT_SUBSCRIPTION_ACTION,
  APP_WIZARD_OPEN_AT_SPECIFIC_STATE,
  APP_DEEPER_SET_RESULTS,
  APP_DEEPER_SET_RESULT_FIELDS,
  APP_DEEPER_ADD_EXAMPLE_REQUEST,
  APP_DEEPER_UPDATE_SELECTED_DEEPER_STATUS_SUCCESS,
  APP_DEEPER_PREDICT_FILE_REQUEST,
  APP_GET_SELECTED_DEEPER_ACTION,
  APP_DEEPER_UNSET_CURRENT_PREDICTION,
  APP_SET_API_KEY_ACTION,
  APP_WIZARD_UPDATE_ANALYSIS_BATCH_STATUS_ACTION,
  APP_WIZARD_SET_MODEL_USE_CASE_ACTION,
  APP_WIZARD_SET_MODEL_INDUSTRY_ACTION,
  APP_WIZARD_SET_MODEL_LANGUAGE_ACTION,
  APP_SET_PRETRAINED_DEEPERS_ACTION,
  APP_SET_REPORTS_ACTION,
  APP_SET_REPORT_DETAIL_ACTION,
  APP_SET_INSIGHT_ACTION,
  APP_SET_AI_MODULES_ACTION,
  APP_SET_AI_MODULE_DETAIL_ACTION,
} from "./actions";
import {
  APP_CLOSE_INTEGRATION_MODAL_ACTION,
  APP_CREATE_INTEGRATION_MODEL_ACTION,
  APP_DEEPER_GET_EXAMPLES_ACTION,
  APP_DEEPER_GET_LATEST_PREDICTIONS_REQUEST_ACTION,
  APP_DEEPER_GET_LATEST_PREDICTIONS_SUCCESS_ACTION,
  APP_DEEPER_PREDICTION_REQUEST_ACTION,
  APP_DEEPER_PREDICTION_SUCCESS_ACTION,
  APP_DEEPER_SET_EXAMPLES_ACTION,
  APP_GET_DEEPERS_ACTION,
  APP_HIDE_DEEPER_CREATION_MODAL,
  APP_HIDE_INTRO_VIDEO_MODAL,
  APP_OPEN_INTEGRATION_MODAL_ACTION,
  APP_SET_ACTIVATED_INTEGRATIONS,
  APP_SET_CURRENT_CAPACITY_ACTION,
  APP_SET_DEEPERS_ACTION,
  APP_SET_SELECTED_DEEPER_ACTION,
  APP_SET_SELECTED_INTEGRATION,
  APP_SHOW_DEEPER_CREATION_MODAL,
  APP_SHOW_INTRO_VIDEO_MODAL,
  APP_UPDATE_ACTIVATED_INTEGRATIONS,
  APP_WIZARD_SET_HELP_WINDOW_COMPONENT_KEY,
} from ".";
import {
  Annotation,
  Deeper,
  DeeperAPIPrediction,
  DeeperPrediction,
  DeeperPredictionRequestPayload,
  DeeperResult,
} from "../dal/deepers";
import { AnalysisBatch } from "../reducers/project";
import { PretrainedDeeper } from "../dal/library";
import { Insight, Report } from "../dal/reports";
import { AiModule } from "../dal/ai_modules";

export type Payload<T> = T extends void ? {} : { payload: T };
export type ActionI<A extends any, P = void> = Action<A> & Payload<P>;

export type ProjectsCreateModelAction = ActionI<
  typeof APP_WIZARD_CREATE_MODEL_ACTION
>;
export type CreateIntegrationModelAction = ActionI<
  typeof APP_CREATE_INTEGRATION_MODEL_ACTION,
  { name: string; projectId: number }
>;

export type SetSubscriptionAction = ActionI<
  typeof APP_SET_CURRENT_SUBSCRIPTION_ACTION,
  { currentPlan: string; lifetimeUser: string }
>;
export type SetCapacityAction = ActionI<
  typeof APP_SET_CURRENT_CAPACITY_ACTION,
  { currentCapacity: CapacityState }
>;

export type SetActivatedIntegrations = ActionI<
  typeof APP_SET_ACTIVATED_INTEGRATIONS,
  Map<string, boolean>
>;
export type UpdatedActivatedIntegrations = ActionI<
  typeof APP_UPDATE_ACTIVATED_INTEGRATIONS
>;
export type SetSelectedIntegration = ActionI<
  typeof APP_SET_SELECTED_INTEGRATION,
  { name: string; active: boolean }
>;
export type OpenIntegrationModal = ActionI<
  typeof APP_OPEN_INTEGRATION_MODAL_ACTION
>;
export type CloseIntegrationModal = ActionI<
  typeof APP_CLOSE_INTEGRATION_MODAL_ACTION
>;

export type WizardStartAction = ActionI<typeof APP_WIZARD_START_ACTION>;
export type WizardReturnToTheLastStepAction = ActionI<
  typeof APP_WIZARD_RETURN_TO_THE_LAST_STEP_ACTION,
  {
    projectId: number;
    batch: AnalysisBatch;
  }
>;
export type WizardOpenModalAction = ActionI<
  typeof APP_WIZARD_OPEN_MODAL_ACTION,
  {
    projectId: number;
    modelType?: string;
  }
>;
export type WizardOpenAtSpecificState = ActionI<
  typeof APP_WIZARD_OPEN_AT_SPECIFIC_STATE,
  {
    projectId: number;
    modelType: string;
    modelTextSubType: string;
    modelId?: number;
  }
>;
export type WizardCloseModalAction = ActionI<
  typeof APP_WIZARD_CLOSE_MODAL_ACTION
>;
export type WizardGoToStepAction = ActionI<
  typeof APP_WIZARD_GO_TO_STEP_ACTION,
  WizardStepName
>;
export type WizardNextStepAction = ActionI<typeof APP_WIZARD_NEXT_STEP_ACTION>;
export type WizardPrevStepAction = ActionI<typeof APP_WIZARD_PREV_STEP_ACTION>;
export type WizardUpdateModelStatusAction = ActionI<
  typeof APP_WIZARD_UPDATE_MODEL_STATUS_ACTION,
  WSModelStatusUpdateEvents
>;
export type WizardUpdateAnalysisBatchStatusAction = ActionI<
  typeof APP_WIZARD_UPDATE_ANALYSIS_BATCH_STATUS_ACTION,
  WSAnalysisBatchStatusUpdateEvents
>;
export type WizardUpdateTypeAction = ActionI<
  typeof APP_WIZARD_UPDATE_TYPE_ACTION,
  {
    modelType?: "text";
    modelTextSubtype?: "conversations" | "generalTextData" | "voe";
    model?: Partial<ProjectTextModel>;
  }
>;
export type WizardSetModelUseCaseAction = ActionI<
  typeof APP_WIZARD_SET_MODEL_USE_CASE_ACTION,
  {
    useCase: string;
  }
>;
export type WizardSetModelIndustryAction = ActionI<
  typeof APP_WIZARD_SET_MODEL_INDUSTRY_ACTION,
  {
    industry: string[];
  }
>;
export type WizardSetModelLanguageAction = ActionI<
  typeof APP_WIZARD_SET_MODEL_LANGUAGE_ACTION,
  {
    lang: string;
  }
>;
export type WizardSetModelBaseFieldsAction = ActionI<
  typeof APP_WIZARD_SET_MODEL_BASE_FIELDS_ACTION,
  {
    model_name: string;
    description: string;
    use_case: string;
    industry: string[];
  }
>;
export type WizardCreateModelSuccessAction = ActionI<
  typeof APP_WIZARD_CREATE_MODEL_SUCCESS_ACTION,
  {
    projectId: number;
    model: ProjectTextModel;
  }
>;

export type WizardSetModelAction = ActionI<
  typeof APP_WIZARD_SET_MODEL_ACTION,
  AnalysisBatch
>;
export type WizardSetModelFileData = ActionI<
  typeof APP_WIZARD_SET_MODEL_FILE_DATA_ACTION,
  ProjectFile
>;
export type WizardSetModelColumnMappingAction = ActionI<
  typeof APP_WIZARD_SET_MODEL_COLUMN_MAPPING_ACTION,
  ModelTypeAndColumnMapping["column_mapping"]
>;

// help window
export type WizardOpenHelpWindow = ActionI<
  typeof APP_WIZARD_OPEN_HELP_WINDOW_ACTION,
  { title: string; text: string }
>;
export type WizardCloseHelpWindow = ActionI<
  typeof APP_WIZARD_CLOSE_HELP_WINDOW_ACTION
>;
export type WizardSetHelpWindowComponentKey = ActionI<
  typeof APP_WIZARD_SET_HELP_WINDOW_COMPONENT_KEY,
  { key: string }
>;

// websocket
export type WebsocketInitAction = ActionI<
  typeof APP_WEBSOCKET_INIT_ACTION,
  { userId: UUID; token: string }
>;
export type WebsocketMessageReceivedAction = ActionI<
  typeof APP_WEBSOCKET_MESSAGE_RECEIVED_ACTION,
  WSMessage
>; // todo: add websocket messages interfaces

export type IntroVideoShowAction = ActionI<
  typeof APP_SHOW_INTRO_VIDEO_MODAL,
  { url: string }
>;
export type IntroVideoHideAction = ActionI<typeof APP_HIDE_INTRO_VIDEO_MODAL>;

export enum WSMessageType { // todo: add all statuses
  ModelStatusUpdate = "status.update",
  DataExportSuccess = "data_export.success",
  DataExportError = "data_export.error",
}

export enum ReportActionType {
  APP_SET_REPORTS_ACTION,
}

// websocket message types
type WSModelStatusUpdateEvent<S extends ModelStatus> = {
  message: {
    type: WSMessageType.ModelStatusUpdate;
    payload: {
      status: S;
      status_description: any;
      object_type: "ml_model";
      object_id: number;
    };
  };
};

type WSAnalysisBatchStatusUpdateEvent<S extends ModelStatus> = {
  message: {
    type: WSMessageType.ModelStatusUpdate;
    payload: {
      status: S;
      status_description: any;
      object_type: "analysis_batch";
      object_id: number;
    };
  };
};

export type WSModelStatusValidatedEvent =
  WSModelStatusUpdateEvent<ModelStatus.Validated>;
export type WSModelStatusExamplesExtractedEvent =
  WSModelStatusUpdateEvent<ModelStatus.ExamplesExtracted>;

export type WSAnalysisBatchStatusValidatedEvent =
  WSAnalysisBatchStatusUpdateEvent<ModelStatus.Validated>;

export type WSAnalysisBatchStatusUpdateEvents =
  WSAnalysisBatchStatusValidatedEvent;

export type WSModelStatusUpdateEvents =
  | WSModelStatusValidatedEvent
  | WSModelStatusExamplesExtractedEvent;

export type WSPresignedUrlErrorEvent = {
  message: {
    type: WSMessageType.DataExportError;
    payload: {
      model_id: number;
    };
  };
};

export type WSPresignedUrlCreatedEvent = {
  message: {
    type: WSMessageType.DataExportSuccess;
    payload: {
      presigned_url: string;
      model_id: number;
    };
  };
};

export type SetAiModulesAction = ActionI<
  typeof APP_SET_AI_MODULES_ACTION,
  AiModule[]
>;

export type SetAiModuleDetailAction = ActionI<
  typeof APP_SET_AI_MODULE_DETAIL_ACTION,
  AiModule
>;

export type SetReportsAction = ActionI<typeof APP_SET_REPORTS_ACTION, Report[]>;

export type SetReportDetailAction = ActionI<
  typeof APP_SET_REPORT_DETAIL_ACTION,
  { report: Report }
>;

export type SetInsightAction = ActionI<
  typeof APP_SET_INSIGHT_ACTION,
  { insight: Insight }
>;

export type AnyAiModuleActionI = SetAiModulesAction | SetAiModuleDetailAction;

export type AnyReportActionI =
  | SetReportsAction
  | SetReportDetailAction
  | SetInsightAction;

export type WSMessage =
  | WSModelStatusUpdateEvents
  | WSAnalysisBatchStatusUpdateEvents
  | WSPresignedUrlCreatedEvent
  | WSPresignedUrlErrorEvent;

export type AnyActionI =
  | ProjectsCreateModelAction
  | WizardStartAction
  | WizardReturnToTheLastStepAction
  | WizardOpenModalAction
  | WizardCloseModalAction
  | WizardGoToStepAction
  | WizardNextStepAction
  | WizardPrevStepAction
  | WizardUpdateModelStatusAction
  | WizardUpdateAnalysisBatchStatusAction
  | WizardUpdateTypeAction
  | WizardSetModelBaseFieldsAction
  | WizardSetModelUseCaseAction
  | WizardSetModelIndustryAction
  | WizardSetModelAction
  | WizardSetModelFileData
  | WizardSetModelLanguageAction
  | WizardSetModelColumnMappingAction
  | WebsocketInitAction
  | WebsocketMessageReceivedAction
  | WizardCreateModelSuccessAction
  | WizardOpenHelpWindow
  | WizardCloseHelpWindow
  | SetSubscriptionAction
  | SetCapacityAction
  | WizardOpenAtSpecificState
  | WizardSetHelpWindowComponentKey
  | SetActivatedIntegrations
  | UpdatedActivatedIntegrations
  | SetSelectedIntegration
  | OpenIntegrationModal
  | CloseIntegrationModal
  | CreateIntegrationModelAction
  | IntroVideoShowAction
  | IntroVideoHideAction;

export type GetDeepersAction = ActionI<typeof APP_GET_DEEPERS_ACTION>;
export type SetDeepersAction = ActionI<typeof APP_SET_DEEPERS_ACTION, Deeper[]>;
export type SetSelectedDeeperAction = ActionI<
  typeof APP_SET_SELECTED_DEEPER_ACTION,
  Deeper
>;
export type GetSelectedDeeperAction = ActionI<
  typeof APP_GET_SELECTED_DEEPER_ACTION,
  { deeper_id: string }
>;
export type ShowDeeperCreationModal = ActionI<
  typeof APP_SHOW_DEEPER_CREATION_MODAL
>;
export type HideDeeperCreationModal = ActionI<
  typeof APP_HIDE_DEEPER_CREATION_MODAL
>;
export type AddDeeperExampleAction = ActionI<
  typeof APP_DEEPER_ADD_EXAMPLE_REQUEST,
  { deeper_id: string; content: string }
>;
export type SetDeeperExamplesAction = ActionI<
  typeof APP_DEEPER_SET_EXAMPLES_ACTION,
  { annotations: Annotation[] }
>;
export type GetDeeperExamplesAction = ActionI<
  typeof APP_DEEPER_GET_EXAMPLES_ACTION,
  { deeper_id: string }
>;
export type DeeperPredictAction = ActionI<
  typeof APP_DEEPER_PREDICTION_REQUEST_ACTION,
  DeeperPredictionRequestPayload
>;
export type DeeperPredictSuccessAction = ActionI<
  typeof APP_DEEPER_PREDICTION_SUCCESS_ACTION,
  DeeperAPIPrediction
>;
export type DeeperUnsetCurrentPredictionAction = ActionI<
  typeof APP_DEEPER_UNSET_CURRENT_PREDICTION
>;
export type DeeperPredictErrorAction = ActionI<
  typeof APP_DEEPER_PREDICTION_REQUEST_ACTION
>;
export type DeeperGetLatestPredictionsAction = ActionI<
  typeof APP_DEEPER_GET_LATEST_PREDICTIONS_REQUEST_ACTION,
  { deeper_id: string }
>;
export type DeeperGetLatestPredictionsSuccessAction = ActionI<
  typeof APP_DEEPER_GET_LATEST_PREDICTIONS_SUCCESS_ACTION,
  { segments: DeeperPrediction[]; total_page_count: number }
>;
export type DeeperSetResultsAction = ActionI<
  typeof APP_DEEPER_SET_RESULTS,
  DeeperResult[]
>;
export type DeeperSetResultFieldsAction = ActionI<
  typeof APP_DEEPER_SET_RESULT_FIELDS,
  string[]
>;
export type DeeperUpdateCurrentDeeperSuccessAction = ActionI<
  typeof APP_DEEPER_UPDATE_SELECTED_DEEPER_STATUS_SUCCESS,
  Deeper
>;
export type DeeperPredictFileAction = ActionI<
  typeof APP_DEEPER_PREDICT_FILE_REQUEST,
  { deeper_id: string }
>;
export type SetPretraineDeepers = ActionI<
  typeof APP_SET_PRETRAINED_DEEPERS_ACTION,
  PretrainedDeeper[]
>;

export type AnyDeeperActionI =
  | GetDeepersAction
  | SetDeepersAction
  | SetSelectedDeeperAction
  | GetSelectedDeeperAction
  | ShowDeeperCreationModal
  | HideDeeperCreationModal
  | AddDeeperExampleAction
  | GetDeeperExamplesAction
  | SetDeeperExamplesAction
  | DeeperPredictAction
  | DeeperPredictSuccessAction
  | DeeperUnsetCurrentPredictionAction
  | DeeperPredictErrorAction
  | DeeperGetLatestPredictionsAction
  | DeeperGetLatestPredictionsSuccessAction
  | DeeperSetResultsAction
  | DeeperSetResultFieldsAction
  | DeeperUpdateCurrentDeeperSuccessAction
  | DeeperPredictFileAction
  | SetPretraineDeepers;

export type SetApiKeyAction = ActionI<
  typeof APP_SET_API_KEY_ACTION,
  { apikey: string | undefined; create_api_key: boolean }
>;
export type AnyApiKeyAction = SetApiKeyAction;
