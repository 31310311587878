import { applyMiddleware, compose, createStore } from 'redux'
import { createEpicMiddleware } from 'redux-observable';
import { createLogger } from 'redux-logger';
import { createAppReducer } from '@deep-talk/shared/src/reducers';
import { rootEpic } from '@deep-talk/shared/src/epics';
import { initialState as authInitialState } from '@deep-talk/shared/src/reducers/auth';
import { initialState as uiInitialState } from '@deep-talk/shared/src/reducers/ui';
import { initialState as projectInitialState } from '@deep-talk/shared/src/reducers/project';
import history from './reusable/history';

const initialState = {
  auth: authInitialState,
  ui: uiInitialState,
  projects: projectInitialState
};



export const configureStore = (initialState) => {
  const epicMiddleware = createEpicMiddleware({
    dependencies: { 
      history: history
    }
  });

  // Extend the store with middlewares (the order here is important)
  // 1. epicMiddleware: Makes redux-observables work
  const middlewares = [
    epicMiddleware,
  ];

  // Ignore next for collection coverage as is something only for DEV environment
  /* istanbul ignore next */
  if (process.env.NODE_ENV === 'development') {
    const logger = createLogger({});
    middlewares.push(logger);
  }

  // Extend the store with enhancers (the order here is important)
  // 1. applyMiddleware: native redux form of track store and actions changeslog events as required, you want to add trackers? this is your place
  const enhancers = [
    applyMiddleware(...middlewares),
  ];

  /* Then create Store */
  const store = createStore(
    createAppReducer(),
    initialState,
    compose(...enhancers),
  );

  /* Finally attach epics to store */
  epicMiddleware.run(rootEpic);

  return store;
};


const store = configureStore(initialState);
export default store