// Auth actions constants
export const APP_LOGIN_REQUEST_ACTION = "Actions/Auth/LOGIN_REQUEST";
export const APP_LOGIN_SUCCESS_ACTION = "Actions/Auth/LOGIN_SUCCESS";
export const APP_LOGIN_ERROR_ACTION = "Actions/Auth/LOGIN_ERROR";
export const APP_LOGOUT_ACTION = "Actions/Auth/APP_LOGOUT";
export const UPDATE_CURRENT_USER_ACTION = "Actions/Auth/UPDATE_CURRENT_USER";

// UI actions constants
export const APP_UI_SET_SIDEBAR_ACTION = "Actions/Ui/APP_UI_SET_SIDEBAR";
export const APP_UI_ADD_TOAST_ACTION = "Actions/Ui/APP_UI_ADD_TOAST";
export const APP_UI_REMOVE_TOAST_ACTION = "Actions/Ui/APP_UI_REMOVE_TOAST";

// Projects actions constants
export const APP_SET_MODEL_SENTIMENT_DISTRIBUTION =
  "Actions/Projects/APP_SET_MODEL_SENTIMENT_DISTRIBUTION";
export const APP_CLEAR_MODEL_SENTIMENT_DISTRIBUTION =
  "Actions/Projects/APP_CLEAR_MODEL_SENTIMENT_DISTRIBUTION";
export const APP_SET_MODEL_ANALYSIS_UNIT_COUNT =
  "Actions/Projects/APP_SET_MODEL_ANALYSIS_UNIT_COUNT";
export const APP_CLEAR_MODEL_ANALYSIS_UNIT_COUNT =
  "Actions/Projects/APP_CLEAR_MODEL_ANALYSIS_UNIT_COUNT";
export const APP_SET_MODEL_TOPIC_SEGMENTS_COUNT =
  "Actions/Projects/APP_SET_MODEL_TOPIC_SEGMENTS_COUNT";
export const APP_CLEAR_MODEL_TOPIC_SEGMENTS_COUNT =
  "Actions/Projects/APP_CLEAR_MODEL_TOPIC_SEGMENTS_COUNT";
export const APP_GATHER_ALL_MODELS_ACTION =
  "Actions/Projects/APP_GATHER_ALL_MODELS_ACTION";
export const APP_UNSET_ALL_MODELS_ACTION =
  "Actions/Projects/APP_UNSET_ALL_MODELS_ACTION";
export const APP_SET_ALL_MODELS_ACTION =
  "Actions/Projects/APP_SET_ALL_MODELS_ACTION";
export const APP_SET_SELECTED_PROJECT_ACTION =
  "Actions/Projects/APP_SET_SELECTED_PROJECT_ACTION";
export const APP_UNSET_SELECTED_PROJECT_ACTION =
  "Actions/Projects/APP_UNSET_SELECTED_PROJECT_ACTION";
export const APP_SET_CURRENT_PROJECT_MODELS_ACTION =
  "Actions/Projects/APP_SET_CURRENT_PROJECT_MODELS_ACTION";
export const APP_SET_CURRENT_PROJECT_FILES_ACTION =
  "Actions/Projects/APP_SET_CURRENT_PROJECT_FILES_ACTION";
export const APP_SET_CURRENT_MODEL_ACTION =
  "Actions/Projects/APP_SET_CURRENT_MODEL_ACTION";
export const APP_UNSET_CURRENT_MODEL_ACTION =
  "Actions/Projects/APP_UNSET_CURRENT_MODEL_ACTION";
export const APP_GET_AND_SET_CURRENT_PROJECT_ACTION =
  "Actions/Projects/APP_GET_AND_SET_CURRENT_PROJECT";
export const APP_GET_AND_SET_CURRENT_PROJECT_MODELS_ACTION =
  "Actions/Projects/APP_GET_AND_SET_CURRENT_PROJECT_MODELS";
export const APP_GET_PROJECTS_REQUEST_ACTION =
  "Actions/Projects/APP_GET_PROJECTS_REQUEST_ACTION";
export const APP_GET_PROJECTS_SUCCESS_ACTION =
  "Actions/Projects/APP_GET_PROJECTS_SUCCESS";
export const APP_GET_PROJECTS_ERROR_ACTION =
  "Actions/Projects/APP_GET_PROJECTS_ERROR";
export const APP_SAVE_PROJECT_REQUEST_ACTION =
  "Actions/Projects/APP_SAVE_PROJECT_REQUEST";
export const APP_EDIT_PROJECT_REQUEST_ACTION =
  "Actions/Projects/APP_EDIT_PROJECT_REQUEST";
export const APP_SAVE_PROJECT_SUCCESS_ACTION =
  "Actions/Projects/APP_SAVE_PROJECT_SUCCESS";
export const APP_SAVE_PROJECT_ERROR_ACTION =
  "Actions/Projects/APP_SAVE_PROJECT_ERROR";
export const APP_SAVE_MODEL_REQUEST_ACTION =
  "Actions/Projects/APP_SAVE_MODEL_REQUEST";
export const APP_SAVE_MODEL_SUCCESS_ACTION =
  "Actions/Projects/APP_SAVE_MODEL_SUCCESS";
export const APP_SAVE_MODEL_ERROR_ACTION =
  "Actions/Projects/APP_SAVE_MODEL_ERROR";
export const APP_GET_METADATA_SUMMARY_REQUEST_ACTION =
  "Actions/Projects/APP_GET_METADATA_SUMMARY_REQUEST";
export const APP_GET_METADATA_SUMMARY_SUCCESS_ACTION =
  "Actions/Projects/APP_GET_METADATA_SUMMARY_SUCCESS";
export const APP_GET_METADATA_SUMMARY_ERROR_ACTION =
  "Actions/Projects/APP_GET_METADATA_SUMMARY_ERROR";
export const APP_SET_METADATA_FILTER_SELECTION_ACTION =
  "Actions/Projects/APP_SET_METADATA_FILTER_SELECTION_ACTION";
export const APP_ACTIVATE_ALL_METADATA_FILTERS_ACTION =
  "Actions/Projects/APP_ACTIVATE_ALL_METADATA_FILTERS_ACTION";
export const APP_CLEAR_METADATA_FILTER_SELECTION_ACTION =
  "Actions/Projects/APP_CLEAR_METADATA_FILTER_SELECTION_ACTION";
export const APP_SET_TEXT_QUERY_ACTION =
  "Actions/Projects/APP_SET_TEXT_QUERY_ACTION";
// Model Results actions constants
export const APP_PREPARE_ANALYSIS_SETTINGS_ACTION =
  "Actions/Analysis/APP_PREPARE_ANALYSIS_SETTINGS_ACTION";
export const APP_PREPARE_DEEPER_SETTINGS_ACTION =
  "Actions/Analysis/APP_PREPARE_DEEPER_SETTINGS_ACTION";
export const APP_PREPARE_DASHBOARD_ACTION =
  "Actions/Projects/APP_PREPARE_DASHBOARD";
export const APP_GET_MODEL_SETTINGS_ACTION =
  "Actions/Projects/APP_GET_MODEL_SETTINGS";
export const APP_SET_MODEL_SETTINGS_ACTION =
  "Actions/Projects/APP_SET_MODEL_SETTINGS";
export const APP_GET_MODEL_BATCHES_ACTION =
  "Actions/Projects/APP_GET_MODEL_BATCHES";
export const APP_SET_MODEL_BATCHES_ACTION =
  "Actions/Projects/APP_SET_MODEL_BATCHES";
export const APP_GET_MODEL_RESULTS_ACTION =
  "Actions/Projects/APP_GET_MODEL_RESULTS";
export const APP_SET_SELECTED_MODEL_RESULT_ACTION =
  "Actions/Projects/APP_SET_SELECTED_MODEL_RESULT_ACTION";
export const APP_SET_SELECTED_MODEL_RESULT_DETAIL_ACTION =
  "Actions/Projects/APP_SET_SELECTED_MODEL_RESULT_DETAIL_ACTION";
export const APP_SET_SELECTED_TOPIC_ACTION =
  "Actions/Projects/APP_SET_SELECTED_TOPIC_ACTION";
export const APP_SET_SELECTED_DATE_RANGE =
  "Actions/Projects/APP_SET_SELECTED_DATE_RANGE";
export const APP_CLEAR_SELECTED_DATE_RANGE =
  "Actions/Projects/APP_CLEAR_SELECTED_DATE_RANGE";
export const APP_CLEAR_SELECTED_TOPIC_ACTION =
  "Actions/Projects/APP_CLEAR_SELECTED_TOPIC_ACTION";
export const APP_SET_SELECTED_MODEL_ID_ACTION =
  "Actions/Projects/APP_SET_SELECTED_MODEL_ID";
export const APP_SET_BREADCRUMBS_DATA_ACTION =
  "Actions/Projects/APP_SET_BREADCRUMBS_DATA_ACTION";
export const APP_SET_TOPIC_TIME_EVOLUTION_DATA =
  "Actions/Projects/APP_SET_TOPIC_TIME_EVOLUTION_DATA";
export const APP_GET_TOPIC_TIME_EVOLUTION_DATA =
  "Actions/Projects/APP_GET_TOPIC_TIME_EVOLUTION_DATA";
export const APP_GET_MODEL_RESULTS_ERROR_ACTION =
  "Actions/Projects/APP_GET_MODEL_RESULTS_ERROR";
export const APP_ADD_TOPIC_TO_TREND = "Actions/Projects/APP_ADD_TOPIC_TO_TREND";
export const APP_REMOVE_TOPIC_FROM_TREND =
  "Actions/Projects/APP_REMOVE_TOPIC_FROM_TREND";
export const APP_CLEAR_TREND_DATA = "Actions/Projects/APP_CLEAR_TREND_DATA";
export const APP_UPDATE_SELECTED_TOPIC_NAME =
  "Actions/Projects/APP_UPDATE_SELECTED_TOPIC_NAME";
export const APP_SET_SELECTED_SENTIMENT_ACTION =
  "Actions/Projects/APP_SET_SELECTED_SENTIMENT";
export const APP_SET_SELECTED_CATEGORY_ACTION =
  "Actions/Projects/APP_SET_SELECTED_CATEGORY";
export const APP_CLEAR_SELECTED_SENTIMENT_ACTION =
  "Actions/Projects/APP_CLEAR_SELECTED_CATEGORY";
export const APP_CLEAR_SELECTED_CATEGORY_ACTION =
  "Actions/Projects/APP_CLEAR_SELECTED_SENTIMENT";
export const APP_SET_SELECTED_ENTITY_ACTION =
  "Actions/Projects/APP_SET_SELECTED_ENTITY";
export const APP_CLEAR_SELECTED_ENTITY_ACTION =
  "Actions/Projects/APP_CLEAR_SELECTED_ENTITY";
export const APP_SET_SELECTED_PLAYER_ACTION =
  "Actions/Projects/APP_SET_SELECTED_PLAYER";
export const APP_CLEAR_SELECTED_PLAYER_ACTION =
  "Actions/Projects/APP_CLEAR_SELECTED_PLAYER";
export const APP_SET_METADATA_SUMMARY_ACTION =
  "Actions/Projects/APP_SET_METADATA_SUMMARY";
export const APP_CLEAR_METADATA_SUMMARY_ACTION =
  "Actions/Projects/APP_CLEAR_METADATA_SUMMARY";
export const APP_CLEAR_MODEL_RESULTS_STATE_ACTION =
  "Actions/Projects/APP_CLEAR_MODEL_RESULTS_STATE";
export const APP_SET_KB_DATA_SOURCES_ACTION =
  "Actions/Projects/APP_SET_KB_DATA_SOURCES_ACTION";

// websocket
export const APP_WEBSOCKET_INIT_ACTION = "Actions/Websocket/APP_WEBSOCKET_INIT";
export const APP_WEBSOCKET_MESSAGE_RECEIVED_ACTION =
  "Actions/Websocket/APP_WEBSOCKET_MESSAGE_RECEIVED";

// intro videos
export const APP_SHOW_INTRO_VIDEO_MODAL = "Actions/IntroVideo/SHOW_MODAL";
export const APP_HIDE_INTRO_VIDEO_MODAL = "Actions/IntroVideo/HIDE_MODAL";

// Model training wizard
export const APP_WIZARD_OPEN_MODAL_ACTION =
  "Actions/Wizard/APP_WIZARD_OPEN_MODAL";
export const APP_WIZARD_CLOSE_MODAL_ACTION =
  "Actions/Wizard/APP_WIZARD_CLOSE_MODAL";

export const APP_WIZARD_RETURN_TO_THE_LAST_STEP_ACTION =
  "Actions/Wizard/APP_WIZARD_RETURN_TO_THE_LAST_STEP";
export const APP_WIZARD_NEXT_STEP_ACTION =
  "Actions/Wizard/APP_WIZARD_NEXT_STEP";
export const APP_WIZARD_PREV_STEP_ACTION =
  "Actions/Wizard/APP_WIZARD_PREV_STEP";
export const APP_WIZARD_GO_TO_STEP_ACTION =
  "Actions/Wizard/APP_WIZARD_GO_TO_STEP";
export const APP_WIZARD_OPEN_AT_SPECIFIC_STATE =
  "Actions/Wizard/APP_WIZARD_OPEN_AT_STATE";

export const APP_WIZARD_START_ACTION = "Actions/Wizard/APP_WIZARD_START";

export const APP_WIZARD_SET_MODEL_USE_CASE_ACTION =
  "Actions/Wizard/APP_WIZARD_SET_MODEL_USE_CASE_ACTION";
export const APP_WIZARD_SET_MODEL_INDUSTRY_ACTION =
  "Actions/Wizard/APP_WIZARD_SET_MODEL_INDUSTRY_ACTION";
export const APP_WIZARD_SET_MODEL_LANGUAGE_ACTION =
  "Actions/Wizard/APP_WIZARD_SET_MODEL_LANGUAGE_ACTION";
export const APP_WIZARD_UPDATE_ANALYSIS_BATCH_STATUS_ACTION =
  "Actions/Wizard/APP_WIZARD_UPDATE_ANALYSIS_BATCH_STATUS";
export const APP_WIZARD_UPDATE_MODEL_STATUS_ACTION =
  "Actions/Wizard/APP_WIZARD_UPDATE_MODEL_STATUS";
export const APP_WIZARD_UPDATE_TYPE_ACTION =
  "Actions/Wizard/APP_WIZARD_UPDATE_TYPE";
export const APP_WIZARD_SET_MODEL_BASE_FIELDS_ACTION =
  "Actions/Wizard/APP_WIZARD_SET_MODEL_BASE_FIELDS";
export const APP_WIZARD_SET_MODEL_ACTION =
  "Actions/Projects/APP_WIZARD_SET_MODEL";
export const APP_WIZARD_SET_MODEL_FILE_DATA_ACTION =
  "Actions/Projects/APP_WIZARD_SET_MODEL_FILE_DATA";
export const APP_WIZARD_SET_MODEL_COLUMN_MAPPING_ACTION =
  "Actions/Projects/APP_WIZARD_SET_MODEL_COLUMN_MAPPING";

export const SET_ADS_ARE_BLOCKED_ACTION = "Actions/App/ADS_ARE_BLOCKED";
export const APP_WIZARD_CREATE_MODEL_ACTION =
  "Actions/Projects/APP_WIZARD_CREATE_MODEL";
export const APP_WIZARD_CREATE_MODEL_SUCCESS_ACTION =
  "Actions/Projects/APP_WIZARD_CREATE_MODEL_SUCCESS";
export const APP_PROJECTS_CREATE_MODEL_ERROR_ACTION =
  "Actions/Projects/APP_PROJECTS_CREATE_MODEL_ERROR";

export const APP_WIZARD_OPEN_HELP_WINDOW_ACTION =
  "Actions/Projects/APP_WIZARD_OPEN_HELP_WINDOW";
export const APP_WIZARD_CLOSE_HELP_WINDOW_ACTION =
  "Actions/Projects/APP_WIZARD_CLOSE_HELP_WINDOW";
export const APP_WIZARD_SET_HELP_WINDOW_COMPONENT_KEY =
  "Actions/Projects/APP_WIZARD_SET_HELP_WINDOW_COMPONENT_KEY";

// Account setting
export const APP_SET_CURRENT_SUBSCRIPTION_ACTION =
  "Actions/AccountSettings/APP_SET_CURRENT_SUBSCRIPTION";
export const APP_SET_CURRENT_CAPACITY_ACTION =
  "Actions/AccountSettings/APP_SET_CURRENT_CAPACITY";

// Integrations
export const APP_SET_ACTIVATED_INTEGRATIONS =
  "Actions/Integrations/APP_SET_ACTIVATED_INTEGRATIONS";
export const APP_UPDATE_ACTIVATED_INTEGRATIONS =
  "Actions/Integrations/APP_UPDATE_ACTIVATED_INTEGRATIONS";
export const APP_SET_SELECTED_INTEGRATION =
  "Actions/Integrations/APP_SET_SELECTED_INTEGRATION";
export const APP_OPEN_INTEGRATION_MODAL_ACTION =
  "Actions/Integrations/APP_OPEN_INTEGRATION_MODAL";
export const APP_CLOSE_INTEGRATION_MODAL_ACTION =
  "Actions/Integrations/APP_CLOSE_INTEGRATION_MODAL";
export const APP_CREATE_INTEGRATION_MODEL_ACTION =
  "Actions/Integrations/APP_CREATE_INTEGRATION_MODEL_ACTION";

// Topic Groups
export const APP_SET_SELECTED_TOPIC_GROUP_ACTION =
  "Actions/TopicGrups/APP_SET_SELECTED_TOPIC_GROUP_ID";
export const APP_CREATE_TOPIC_GROUP_REQUEST_ACTION =
  "Actions/TopicGrups/APP_CREATE_TOPIC_GROUP_REQUEST_ACTION";
export const APP_GET_TOPIC_GROUPS_ACTION =
  "Actions/TopicGrups/APP_GET_TOPIC_GROUPS_ACTION";
export const APP_GET_TOPIC_GROUPS_SUCCESS_ACTION =
  "Actions/TopicGrups/APP_GET_TOPIC_GROUPS_SUCCESS_ACTION";
export const APP_GET_TOPIC_GROUP_MEMBERSHIPS_ACTION =
  "Actions/TopicGroups/APP_GET_TOPIC_GROUP_MEMBERSHIPS_ACTION";
export const APP_GET_TOPIC_GROUP_MEMBERSHIPS_SUCCESS_ACTION =
  "Actions/TopicGroups/APP_GET_TOPIC_GROUP_MEMBERSHIPS_SUCCESS_ACTION";
export const APP_GET_TOPIC_GROUP_MEMBERSHIPS_BY_GROUP_ACTION =
  "Actions/TopicGroups/APP_GET_TOPIC_GROUP_MEMBERSHIPS_BY_GROUP_ACTION";
export const APP_GET_TOPIC_GROUP_MEMBERSHIPS_BY_GROUP_SUCCESS_ACTION =
  "Actions/TopicGroups/APP_GET_TOPIC_GROUP_MEMBERSHIPS_BY_GROUP_SUCCESS_ACTION";
export const APP_CREATE_TOPIC_GROUP_MEMBERSHIPS_ACTION =
  "Actions/TopicGroups/APP_APP_CREATE_TOPIC_GROUP_MEMBERSHIPS_ACTION";
export const APP_DELETE_TOPIC_GROUP_MEMBERSHIPS_ACTION =
  "Actions/TopicGroups/APP_APP_DELETE_TOPIC_GROUP_MEMBERSHIPS_ACTION";
export const APP_DELETE_TOPIC_GROUP_ACTION =
  "Actions/TopicGroups/APP_DELETE_TOPIC_GROUP_ACTION";
export const APP_EDIT_TOPIC_GROUP_ACTION =
  "Actions/TopicGroups/APP_EDIT_TOPIC_GROUP_ACTION";
export const APP_UNSET_TOPIC_GROUP_MEMBERSHIPS_BY_GROUP_ACTION =
  "Actions/TopicGroups/APP_UNSET_TOPIC_GROUP_MEMBERSHIPS_BY_GROUP_ACTION";

// Deepers
export const APP_SHOW_DEEPER_CREATION_MODAL =
  "Actions/Deepers/APP_SHOW_DEEPER_CREATION_MODAL";
export const APP_HIDE_DEEPER_CREATION_MODAL =
  "Actions/Deepers/APP_HIDE_DEEPER_CREATION_MODAL";
export const APP_SAVE_DEEPER_REQUEST =
  "Actions/Deepers/APP_SAVE_DEEPER_REQUEST";
export const APP_SAVE_DEEPER_SUCCESS =
  "Actions/Deepers/APP_SAVE_DEEPER_SUCCESS";
export const APP_SAVE_DEEPER_ERROR = "Actions/Deepers/APP_SAVE_DEEPER_ERROR";
export const APP_DEEPERS_ERROR_ACTION =
  "Actions/Deepers/APP_DEEPERS_ERROR_ACTION";
export const APP_GET_PRETRAINED_DEEPERS_ACTION =
  "Actions/Deepers/APP_GET_PRETRAINED_DEEPERS_ACTION";
export const APP_SET_PRETRAINED_DEEPERS_ACTION =
  "Actions/Deepers/APP_SET_PRETRAINED_DEEPERS_ACTION";
export const APP_GET_DEEPERS_ACTION = "Actions/Deepers/APP_GET_DEEPERS_ACTION";
export const APP_SET_DEEPERS_ACTION = "Actions/Deepers/APP_SET_DEEPERS_ACTION";
export const APP_GET_SELECTED_DEEPER_ACTION =
  "Actions/Deepers/APP_GET_SELECTED_DEEPER_ACTION";
export const APP_SET_SELECTED_DEEPER_ACTION =
  "Actions/Deepers/APP_SET_SELECTED_DEEPER_ACTION";
export const APP_DEEPER_ADD_EXAMPLE_REQUEST =
  "Actions/Deepers/APP_DEEPER_ADD_EXAMPLE_REQUEST";
export const APP_DEEPER_ADD_EXAMPLE_SUCCESS =
  "Actions/Deepers/APP_DEEPER_ADD_EXAMPLE_SUCCESS";
export const APP_DEEPER_ADD_EXAMPLE_ERROR =
  "Actions/Deepers/APP_DEEPER_ADD_EXAMPLE_ERROR";
export const APP_DEEPER_GET_EXAMPLES_ACTION =
  "Actions/Deepers/APP_DEEPER_GET_EXAMPLES_ACTION";
export const APP_DEEPER_SET_EXAMPLES_ACTION =
  "Actions/Deepers/APP_DEEPER_SET_EXAMPLES_ACTION";
export const APP_DEEPER_TAG_EXAMPLE_ACTION =
  "Actions/Deepers/APP_DEEPER_TAG_EXAMPLE_ACTION";
export const APP_DEEPER_PREDICTION_REQUEST_ACTION =
  "Actions/Deepers/APP_DEEPER_PREDICTION_REQUEST";
export const APP_DEEPER_PREDICTION_SUCCESS_ACTION =
  "Actions/Deepers/APP_DEEPER_PREDICTION_SUCCESS";
export const APP_DEEPER_PREDICTION_ERROR_ACTION =
  "Actions/Deepers/APP_DEEPER_PREDICTION_ERROR";
export const APP_DEEPER_UNSET_CURRENT_PREDICTION =
  "Actions/Deepers/APP_DEEPER_UNSET_CURRENT_PREDICTION";
export const APP_DEEPER_GET_LATEST_PREDICTIONS_REQUEST_ACTION =
  "Actions/Deepers/APP_DEEPER_GET_LATEST_PREDICTIONS_REQUEST_ACTION";
export const APP_DEEPER_GET_LATEST_PREDICTIONS_SUCCESS_ACTION =
  "Actions/Deepers/APP_DEEPER_GET_LATEST_PREDICTIONS_SUCCESS_ACTION";
export const APP_DEEPER_GET_LATEST_PREDICTIONS_ERROR_ACTION =
  "Actions/Deepers/APP_DEEPER_GET_LATEST_PREDICTIONS_ERROR_ACTION";
export const APP_DEEPER_UPDATE_REQUEST =
  "Actions/Deepers/APP_DEEPER_UPDATE_REQUEST";
export const APP_DEEPER_UPDATE_SUCCESS =
  "Actions/Deepers/APP_DEEPER_UPDATE_SUCCESS";
export const APP_DEEPER_UPDATE_ERROR =
  "Actions/Deepers/APP_DEEPER_UPDATE_ERROR";
export const APP_DEEPER_GET_RESULTS_REQUEST =
  "Actions/Deepers/APP_DEEPER_GET_RESULTS_REQUEST";
export const APP_DEEPER_GET_RESULTS_SUCCESS =
  "Actions/Deepers/APP_DEEPER_GET_RESULTS_SUCCESS";
export const APP_DEEPER_GET_RESULTS_ERROR =
  "Actions/Deepers/APP_DEEPER_GET_RESULTS_ERROR";
export const APP_DEEPER_SET_RESULTS = "Actions/Deepers/APP_DEEPER_SET_RESULTS";
export const APP_DEEPER_SET_RESULT_FIELDS =
  "Actions/Deepers/APP_DEEPER_SET_RESULT_FIELDS";
export const APP_DEEPER_UPDATE_RESULT_FIELDS =
  "Actions/Deepers/APP_DEEPER_UPDATE_RESULT_FIELDS";
export const APP_DEEPER_UPDATE_SELECTED_DEEPER_STATUS_REQUEST =
  "Actions/Deepers/APP_DEEPER_UPDATE_SELECTED_DEEPER_STATUS_REQUEST";
export const APP_DEEPER_UPDATE_SELECTED_DEEPER_STATUS_SUCCESS =
  "Actions/Deepers/APP_DEEPER_UPDATE_SELECTED_DEEPER_STATUS_SUCCESS";
export const APP_DEEPER_UPDATE_SELECTED_DEEPER_STATUS_ERROR =
  "Actions/Deepers/APP_DEEPER_UPDATE_SELECTED_DEEPER_STATUS_ERROR";
export const APP_DEEPER_PREDICT_FILE_REQUEST =
  "Actions/Deepers/APP_DEEPER_PREDICT_FILE_REQUEST";
export const APP_DEEPER_PREDICT_FILE_SUCCESS =
  "Actions/Deepers/APP_DEEPER_PREDICT_FILE_SUCCESS";
export const APP_DEEPER_PREDICT_FILE_ERROR =
  "Actions/Deepers/APP_DEEPER_PREDICT_FILE_ERROR";
export const APP_DEEPER_REMOVE_RESULT_REQUEST =
  "Actions/Deepers/APP_DEEPER_REMOVE_RESULT_REQUEST";
export const APP_DEEPER_REMOVE_RESULT_SUCCESS =
  "Actions/Deepers/APP_DEEPER_REMOVE_RESULT_SUCCESS";
export const APP_DEEPER_TAG_PREDICTION_REQUEST =
  "Actions/Deepers/APP_DEEPER_TAG_PREDICTION_REQUEST";
export const APP_DEEPER_TAG_PREDICTION_SUCCESS =
  "Actions/Deepers/APP_DEEPER_TAG_PREDICTION_SUCCESS";
export const APP_DEEPER_TAG_PREDICTION_ERROR =
  "Actions/Deepers/APP_DEEPER_TAG_PREDICTION_ERROR";
export const APP_DEEPER_TAG_RESULT_REQUEST =
  "Actions/Deepers/APP_DEEPER_TAG_RESULT_REQUEST";
export const APP_DEEPER_TAG_RESULT_SUCCESS =
  "Actions/Deepers/APP_DEEPER_TAG_RESULT_SUCCESS";
export const APP_DEEPER_TAG_RESULT_ERROR =
  "Actions/Deepers/APP_DEEPER_TAG_RESULT_ERROR";
export const APP_DEEPER_DELETE_DEEPER_REQUEST =
  "Actions/Deepers/APP_DEEPER_DELETE_DEEPER_REQUEST";
export const APP_GET_API_KEY_REQUEST = "Actions/ApiKey/APP_GET_API_KEY_REQUEST";
export const APP_GET_API_KEY_SUCCESS = "Actions/ApiKey/APP_GET_API_KEY_SUCCESS";
export const APP_GET_API_KEY_ERROR = "Actions/ApiKey/APP_GET_API_KEY_ERROR";
export const APP_PATCH_API_KEY_REQUEST =
  "Actions/ApiKey/APP_PATCH_API_KEY_REQUEST";
export const APP_PATCH_API_KEY_SUCCESS =
  "Actions/ApiKey/APP_PATCH_API_KEY_SUCCESS";
export const APP_PATCH_API_KEY_ERROR = "Actions/ApiKey/APP_PATCH_API_KEY_ERROR";
export const APP_CREATE_API_KEY_REQUEST =
  "Actions/ApiKey/APP_CREATE_API_KEY_REQUEST";
export const APP_CREATE_API_KEY_SUCCESS =
  "Actions/ApiKey/APP_CREATE_API_KEY_SUCCESS";
export const APP_CREATE_API_KEY_ERROR =
  "Actions/ApiKey/APP_CREATE_API_KEY_ERROR";
export const APP_SET_API_KEY_ACTION = "Actions/ApiKey/APP_SET_API_KEY_ACTION";
export const APP_DELETE_API_KEY_REQUEST =
  "Actions/ApiKey/APP_DELETE_API_KEY_REQUEST";
export const APP_DELETE_API_KEY_SUCCESS =
  "Actions/ApiKey/APP_DELETE_API_KEY_SUCCESS";
export const APP_DELETE_API_KEY_ERROR =
  "Actions/ApiKey/APP_DELETE_API_KEY_ERROR";

// Payment Required
export const APP_PAYMENT_REQUIRED_ACTION =
  "Actions/Billing/PAYMENT_REQUIRED_ACTION";
export const APP_REQUEST_BILLING_INFORMATION =
  "Actions/Billing/REQUEST_BILLING_INFORMATION";
export const APP_GET_BILLING_INFORMATION_REQUEST =
  "Actions/Billing/APP_GET_BILLING_INFORMATION_REQUEST";
export const APP_GET_BILLING_INFORMATION_SUCCESS =
  "Actions/Billing/APP_GET_BILLING_INFORMATION_SUCCESS";
export const APP_GET_BILLING_INFORMATION_ERROR =
  "Actions/Billing/APP_GET_BILLING_INFORMATION_ERROR";
// Data Export
export const DATA_EXPORT_RESET = "Actions/Export/Reset";
export const DATA_EXPORT_REQUEST = "Actions/Export/Request";
export const DATA_EXPORT_SUCCESS = "Actions/Export/Success";
export const DATA_EXPORT_ERROR = "Actions/Export/Error";
// Dashboard
export const APP_GET_NUMBER_TOPIC_SEGMENTS_REQUEST =
  "Actions/Dashboard/APP_GET_NUMBER_TOPIC_SEGMENTS_REQUEST";
export const APP_GET_SUNBURST_DATA =
  "Actions/Dashboard/APP_GET_SUNBURST_DATA_REQUEST";
export const APP_SET_SUNBURST_DATA = "Actions/Dashboard/APP_SET_SUNBURST_DATA";

// Reports
export const APP_GET_REPORTS_ACTION = "Actions/Reports/APP_GET_REPORTS_REQUEST";
export const APP_SET_REPORTS_ACTION = "Actions/Reports/APP_SET_REPORTS_ACTION";
export const APP_CREATE_REPORT_ACTION =
  "Actions/Reports/APP_CREATE_REPORT_ACTION";
export const APP_GET_REPORT_DETAIL_ACTION =
  "Actions/Reports/APP_GET_REPORT_DETAIL_REQUEST";
export const APP_SET_REPORT_DETAIL_ACTION =
  "Actions/Reports/APP_SET_REPORT_DETAIL_ACTION";
export const APP_SET_INSIGHT_ACTION = "Actions/Insights/APP_SET_INSIGHT_ACTION";

// AI Modules
export const APP_GET_AI_MODULES_ACTION =
  "Actions/Reports/APP_GET_AI_MODULES_ACTION";
export const APP_SET_AI_MODULES_ACTION =
  "Actions/Reports/APP_SET_AI_MODULES_ACTION";
export const APP_CREATE_AI_MODULE_ACTION =
  "Actions/Reports/APP_CREATE_AI_MODULE_ACTION";
export const APP_GET_AI_MODULE_DETAIL_ACTION =
  "Actions/Reports/APP_GET_AI_MODULE_DETAIL_REQUEST";
export const APP_SET_AI_MODULE_DETAIL_ACTION =
  "Actions/Reports/APP_SET_AI_MODULE_DETAIL_ACTION";
