import { DEEPTALK_API_HOST, DEEPTALK_BILLING_HOST } from "../constants";
import api from "../utils/ApiKit";
import { formatCapacityStateResponse } from "./responseFormatters";

export interface CapacityStateResponse {
  account_uuid: string;
  data: {
    capacity: {
      purchased: {
        packages: number;
        subscription: number;
        total: number;
      };
      reserved: number;
      used: number;
    };
  };
  subscription_details: {
    name: string;
    renewal_date: string;
    status: string;
    is_lifetime_subscription: string;
    display_name: string;
    price: number;
    n_analyses: number;
    n_deepers: number;
    n_metadata_cols: number;
    n_data_rows: number;
    enabled_pipelines: string[];
    summary: string[];
  };
}

export interface CapacityState {
  display_name: string;
  is_lifetime_subscription: boolean;
  name: string;
  n_analyses: number;
  n_data_rows_subscription: number;
  n_deepers: number;
  n_metadata_cols: number;
  price: number;
  renewal_date: string;
  status: string;
  enabled_pipelines: string[];
  purchased: {
    packages: number;
    subscription: number;
    total: number;
  };
  reserved: number;
  used: number;
  available: number;
  summary: string[];
}

export const getCapacityStateDeep = () => {
  const url = `${DEEPTALK_BILLING_HOST}/capacity`;
  return api.getInstance().get<CapacityStateResponse>(url);
};

export const getCapacityState = async () => {
  const res = await getCapacityStateDeep();
  const responseData = res.data;
  return formatCapacityStateResponse(responseData);
};
