const availableMetadata = [
  { value: "cnum", label: "Numerical", class: "numerical" },
  { value: "ccat", label: "Categorical", class: "categorical" },
  { value: "identifier", label: "Identifiers", class: "categorical" },
  { value: "binary", label: "Binary", class: "categorical" },
  { value: "nps", label: "NPS score", class: "numerical" },
  { value: "csat", label: "CSAT score", class: "numerical" },
  { value: "ces", label: "CES score", class: "numerical" },
  { value: "age", label: "Age", class: "numerical" },
  { value: "brand", label: "Brand", class: "categorical" },
  { value: "city", label: "City", class: "categorical" },
  { value: "country", label: "Country", class: "categorical" },
  { value: "email", label: "E-mail", class: "categorical" },
  { value: "gender", label: "Gender", class: "categorical" },
  { value: "rating", label: "Other Rating", class: "numerical" },
  { value: "region", label: "Region", class: "categorical" },
];

export const metadataOptions = availableMetadata.map((elem) => {
  return { label: elem.label, value: elem.value };
});

const labels = availableMetadata.map((elem) => {
  return elem.label;
});
const classes = availableMetadata.map((elem) => {
  return elem.class;
});

function toObject(arr: { value: string; class: string }[]) {
  var rv: { [index: string]: string } = {};
  for (var i = 0; i < arr.length; ++i) {
    const c = arr[i].class;
    const v = arr[i].value;
    rv[v] = c;
  }
  return rv;
}

export const metadataTyper = toObject(availableMetadata);
