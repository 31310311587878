/*
 *
 * Auth reducer
 *
 */

import { ActionWithPayload } from "./interfaces";
import {
  APP_LOGIN_SUCCESS_ACTION,
  APP_LOGOUT_ACTION,
  UPDATE_CURRENT_USER_ACTION,
} from "../constants";

type User = {
  token: string;
  person: {
    name: string;
    id: string;
    account: string;
    email: string;
    given_name: string;
    family_name: string;
    roles?: string[];
    project_ids?: string;
  };
};

export type AppAuthState = {
  currentUser?: User;
};

export const initialState: AppAuthState = {};

export const authReducer = (
  state = initialState,
  action: ActionWithPayload<User>
) => {
  switch (action.type) {
    case APP_LOGIN_SUCCESS_ACTION:
      return {
        ...state,
        currentUser: action.payload,
      };
    case APP_LOGOUT_ACTION:
      return {
        ...state,
        currentUser: undefined,
      };
    case UPDATE_CURRENT_USER_ACTION:
      return {
        ...state,
        currentUser: action.payload,
      };

    default:
      return state;
  }
};
