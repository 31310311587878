import { APP_ADD_TOPIC_TO_TREND, APP_CLEAR_TREND_DATA, APP_REMOVE_TOPIC_FROM_TREND } from "../constants";

export type TrendData = TrendDatum[];

export type TimeEvolutionDataPoint = {
    month: string;
    conversations_count: number;
  }

export type TopicTimeEvolutionData = TimeEvolutionDataPoint[];

export type TrendDatum = {
  topicId: string;
  label: string;
  level: number;
  data: TopicTimeEvolutionData;
}

const initialState = [] as TrendData;

type TrendAction = {
    type: string;
    payload: {
        topicId: string;
        data: TopicTimeEvolutionData;
        label: string;
        level: number;
    }
}

const trendReducer = (state: TrendData = initialState, action: TrendAction) => {
    const {payload, type} = action;
    switch(type) {
        case APP_ADD_TOPIC_TO_TREND:
            const newState = JSON.parse(JSON.stringify(state));
            const filt = newState.filter((elem: TrendDatum) => {return elem.topicId === payload.topicId})
            if (filt.length === 1) {
                return newState
            }
            newState.push(payload);
            return newState

        case APP_REMOVE_TOPIC_FROM_TREND:
            const filtered = state.filter((elem) => {return elem.topicId !== payload.topicId});
            return filtered

        case APP_CLEAR_TREND_DATA:
            return []

    default:
        return state;
    }
}

export {
    trendReducer
}