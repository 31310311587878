import { Observable, Store } from "redux";
import { ActionsObservable, combineEpics, ofType } from "redux-observable";
import { forkJoin, from, of } from "rxjs";
import {
  catchError,
  concatMap,
  ignoreElements,
  map,
  mergeMap,
  tap,
} from "rxjs/operators";
import APIKit from "../utils/ApiKit";
import { EpicDependencies } from ".";

import {
  APP_CREATE_AI_MODULE_ACTION,
  APP_GET_AI_MODULE_DETAIL_ACTION,
  APP_GET_AI_MODULES_ACTION,
  APP_SET_AI_MODULE_DETAIL_ACTION,
  APP_SET_AI_MODULES_ACTION,
  APP_UI_ADD_TOAST_ACTION,
  DEEPTALK_API_HOST,
} from "../constants";
import { ReportCreateReqData } from "../dal/reports";
import { ActionWithPayload } from "../reducers/interfaces";
import { AiModule, BinaryClassifierCreationPayload } from "../dal/ai_modules";

const getAiModulesRequest = () => {
  return APIKit.getInstance().get<AiModule[]>(
    `${DEEPTALK_API_HOST}/models/deepers/`
  );
};

const getAiModuleDetailRequest = (module_id: string) => {
  return APIKit.getInstance().get<AiModule>(
    `${DEEPTALK_API_HOST}/ai_modules/${module_id}/`
  );
};

export const saveAiModuleRequest = (data: BinaryClassifierCreationPayload) => {
  return APIKit.getInstance().post<AiModule>(
    `${DEEPTALK_API_HOST}/ai_modules/`,
    data
  );
};

export const deleteAiModuleRequest = (module_id: string) => {
  return APIKit.getInstance().delete(
    `${DEEPTALK_API_HOST}/ai_module/${module_id}/`
  );
};

const getAiModulesEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_GET_AI_MODULES_ACTION),
    mergeMap(() =>
      from(getAiModulesRequest()).pipe(
        map((response) => ({
          type: APP_SET_AI_MODULES_ACTION,
          payload: response.data,
        })),
        catchError((error) =>
          of({
            type: APP_UI_ADD_TOAST_ACTION,
            payload: {
              id: APP_SET_AI_MODULES_ACTION,
              type: "error",
              text: "Could not get ai modules",
              duration: 3500,
            },
          })
        )
      )
    )
  );
};

const getAiModuleDetailEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_GET_AI_MODULE_DETAIL_ACTION),
    mergeMap((action: ActionWithPayload<any>) =>
      from(getAiModuleDetailRequest(action.payload.module_id)).pipe(
        map((response) => ({
          type: APP_SET_AI_MODULE_DETAIL_ACTION,
          payload: response.data,
        })),
        catchError((error) =>
          of({
            type: APP_UI_ADD_TOAST_ACTION,
            payload: {
              id: APP_SET_AI_MODULE_DETAIL_ACTION,
              type: "error",
              text: "Could not get ai modules details",
              duration: 3500,
            },
          })
        )
      )
    )
  );
};

const saveAiModuleEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_CREATE_AI_MODULE_ACTION),
    mergeMap((action: ActionWithPayload<any>) =>
      from(saveAiModuleRequest(action.payload)).pipe(
        mergeMap((response) => [{ type: APP_GET_AI_MODULES_ACTION }]),
        catchError((error) => {
          if (error.response.status === 402) {
            return from([
              {
                type: APP_UI_ADD_TOAST_ACTION,
                payload: {
                  id: APP_CREATE_AI_MODULE_ACTION,
                  type: "error",
                  text: "Could not create ai modules",
                  duration: 3500,
                },
              },
            ]);
          } else {
            return from([
              {
                type: APP_UI_ADD_TOAST_ACTION,
                payload: {
                  id: APP_CREATE_AI_MODULE_ACTION,
                  type: "error",
                  text: "Could not create ai module",
                  duration: 3500,
                },
              },
            ]);
          }
        })
      )
    )
  );
};

export const aiModulesEpic = combineEpics(
  getAiModuleDetailEpic,
  getAiModulesEpic,
  saveAiModuleEpic
);
