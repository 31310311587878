/*
 *
 * Root Epic
 *
 */
import { combineEpics } from "redux-observable";

import { authEpic } from "./auth";
import { projectEpic } from "./project";
import { modelEpic } from "./model";
import { modelResultEpic } from "./model-results";
import { websocketEpic } from "./websocket";
import { integrationEpic } from "./integrations";
import { deeperEpic } from "./deepers";
import { apiKeyEpic } from "./api";
import { billingEpic } from "./billing";
import { analysisSettingsEpic } from "./analysis/settings";
import { reportsEpic } from "./reports";
import { aiModulesEpic } from "./ai_modules";

export type EpicDependencies = {
  history: any;
};

export const rootEpic = combineEpics(
  authEpic,
  projectEpic,
  modelEpic,
  websocketEpic,
  integrationEpic,
  modelResultEpic,
  deeperEpic,
  apiKeyEpic,
  billingEpic,
  analysisSettingsEpic,
  reportsEpic,
  aiModulesEpic
);
