/*
 *
 * Auth reducer
 *
 */

import { ActionWithPayload } from "./interfaces";
import { APP_UI_SET_SIDEBAR_ACTION } from "../constants";

export type AppUiState = {
  sidebarShow?: string | boolean;
  loading: {
    actions: {
      [index: string]: {
        name: string;
        loading: boolean;
      };
    }
  }
}

export const initialState: AppUiState = {
  sidebarShow: 'responsive',
  loading: {
    actions: {}
  }
};

export const uiReducer = (state = initialState, action: ActionWithPayload<AppUiState>) => {
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|ERROR)/.exec(type);

  // not a *_REQUEST / *_SUCCESS /  *_ERROR actions, so we ignore them
  if (!matches) {
    switch (action.type) {
      case APP_UI_SET_SIDEBAR_ACTION:
        return {
          ...state,
          sidebarShow: action.payload.sidebarShow,
        };

      default:
        return state;
    }
  }

  const [, requestPrefix, requestState] = matches;
  return {
    ...state,
    loading: {
      ...state.loading,
      actions: {
        ...state.loading.actions,
        // Store whether a request is happening at the moment or not
        // e.g. will be true when receiving APP_GET_PROJECTS_REQUEST_ACTION
        //      and false when receiving APP_GET_PROJECTS_SUCCESS_ACTION / APP_GET_PROJECTS_ERROR_ACTION
        [`${requestPrefix}_REQUEST`]: {
            name: `${requestPrefix}_REQUEST`,
            loading: requestState === 'REQUEST',
        }
      }
    }
  };
};

export default uiReducer;
