import {
  APP_SET_CURRENT_SUBSCRIPTION_ACTION,
  APP_SET_CURRENT_CAPACITY_ACTION,
  SET_ADS_ARE_BLOCKED_ACTION,
} from "../constants";
import { CapacityState } from "../dal/settings";
import { ActionWithPayload } from "./interfaces";

type State = {
  subscription: CapacityState | undefined;
  adsBlocked: boolean | undefined;
};

const initialState: State = {
  subscription: undefined,
  adsBlocked: undefined,
};

export const accountSettingReducer = (
  state = initialState,
  action: ActionWithPayload<State>
) => {
  switch (action.type) {
    case APP_SET_CURRENT_SUBSCRIPTION_ACTION:
      return {
        ...state,
        subscription: action.payload.subscription,
      };

    case SET_ADS_ARE_BLOCKED_ACTION:
      return {
        ...state,
        adsBlocked: action.payload.adsBlocked,
      };

    default:
      return state;
  }
};
