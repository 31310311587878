import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Redirect, Route, Switch } from "react-router-dom";
import { Router } from "react-router-dom";
import { isAuthenticated } from "@deep-talk/shared/src/selectors/Auth";
import "./scss/style.scss";
import { useKeycloak } from "@react-keycloak/web";
import { CSpinner } from "@coreui/react";
import history from "./reusable/history";
import { lazyRetry } from "@deep-talk/shared/src/utils";

const loading = (
  <div className="flex-center">
    <CSpinner color="brand" style={{ width: "8rem", height: "8rem" }} />
  </div>
);
// Containers
const TheEntryPoint = React.lazy(
  () => lazyRetry(() => import("./containers/TheEntryPoint")) as any
);

// Error Boundary
const ErrorBoundary = React.lazy(
  () => lazyRetry(() => import("./components/error/ErrorBoundary")) as any
);

// Pages
const Login = React.lazy(
  () => lazyRetry(() => import("./views/pages/login/Login")) as any
);
const Register = React.lazy(
  () => lazyRetry(() => import("./views/pages/register/Register")) as any
);
const Page404 = React.lazy(
  () => lazyRetry(() => import("./views/pages/page404/Page404")) as any
);
const Page500 = React.lazy(
  () => lazyRetry(() => import("./views/pages/page500/Page500")) as any
);

const PrivateRoute = ({ component: Component, roles, ...rest }: any) => {
  const { keycloak, initialized } = useKeycloak();

  useEffect(() => {
    if (keycloak && initialized) {
      if (keycloak && initialized) {
        keycloak.onTokenExpired = () => keycloak.updateToken(50);
      }
      return () => {
        if (keycloak) keycloak.onTokenExpired = () => {};
      };
    }
    return () => {
      if (keycloak) keycloak.onTokenExpired = () => {};
    };
  }, [initialized, keycloak]);

  return (
    <Route
      {...rest}
      render={(props) =>
        keycloak?.authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const App = () => {
  const { initialized } = useKeycloak();

  if (!initialized) {
    return loading;
  }

  return (
    <Router history={history}>
      <React.Suspense fallback={loading}>
        <ErrorBoundary>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/appsumo/:email" component={Register} />
            <Route exact path="/404" component={Page404} />
            <Route exact path="/500" component={Page500} />
            <PrivateRoute
              roles={["RealmAdmin"]}
              path="/"
              name="Home"
              component={TheEntryPoint}
            />
          </Switch>
        </ErrorBoundary>
      </React.Suspense>
    </Router>
  );
};

const mapStateToProps = createStructuredSelector<any, any>({
  authenticated: isAuthenticated,
});

export default connect(mapStateToProps)(App);
