import { createSelector } from "reselect";
import { AppState } from "../reducers/interfaces";
import { select } from "d3";

type User = {
  token: string;
  person: {
    name: string;
    id: string;
    account: string;
    email: string;
    given_name: string;
    family_name: string;
  };
};

/**
 * Direct selector to the login state domain
 */
const selectAuthDomain = (state: AppState) => state.auth;

export const isAuthenticated = createSelector(selectAuthDomain, (substate) =>
  Boolean(substate.currentUser?.token)
);

export const selectAuthData = createSelector(
  selectAuthDomain,
  (substate) => substate.currentUser
);

export const selectIsInviteeAiConsultant = createSelector(
  selectAuthDomain,
  (substate) =>
    Boolean(
      substate.currentUser?.person?.roles?.includes("dt-invitee-ai-consultant")
    ) ?? false
);

export const selectIsProjectManager = createSelector(
  selectAuthDomain,
  (substate) =>
    Boolean(
      substate.currentUser?.person?.roles?.includes("dt-project-manager")
    ) ?? false
);

export const selectIsInvitee = createSelector(
  selectAuthDomain,
  (substate) =>
    Boolean(substate.currentUser?.person?.roles?.includes("dt-invitee")) ??
    false
);

export const selectIsAnyInvitee = createSelector(
  selectIsInviteeAiConsultant,
  selectIsInvitee,
  (light, invitee) => {
    return light || invitee;
  }
);

export const selectIsAdminUser = createSelector(
  selectIsInviteeAiConsultant,
  selectIsInvitee,
  selectIsProjectManager,
  (light, invitee, pm) => {
    return !(light || invitee || pm);
  }
);

export const selectIsMarcoOrDemo = createSelector(
  selectAuthDomain,
  (substate) => {
    return [
      "f5bc56bc-0936-4af2-b872-3a69b4463e45",
      "61abebda-767b-4245-9fb5-cc0d52df07d7",
      "91aeeef3-762a-4aad-b08d-39612dfc6871",
      "31c2a899-8523-4c09-8b08-8bba846303d6",
      "8243760e-be5c-4181-98e4-73eae2d0b5a4",
      "a5e05c05-19f0-4f5d-9766-3d0873179955",
      "ae7801eb-b3dc-491a-8cc1-b62bb79d82e1",
    ].includes(substate.currentUser?.person.account ?? "");
  }
);

export type isAuthenticatedT = ReturnType<typeof isAuthenticated>;
