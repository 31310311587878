import {
  DATA_EXPORT_REQUEST,
  DATA_EXPORT_SUCCESS,
  DATA_EXPORT_ERROR,
  DATA_EXPORT_RESET,
} from "../constants";
import { ActionWithPayload } from "./interfaces";

export type ExporterState = {
  status: string;
  url: string | undefined;
};

const initialState: ExporterState = {
  status: "default",
  url: undefined,
};

export const exporterReducer = (
  state = initialState,
  action: ActionWithPayload<ExporterState>
) => {
  switch (action.type) {
    case DATA_EXPORT_RESET: {
      return {
        status: "default",
        url: undefined,
      };
    }

    case DATA_EXPORT_REQUEST: {
      return {
        status: "requested",
        url: undefined,
      };
    }

    case DATA_EXPORT_SUCCESS: {
      return {
        status: "success",
        url: action.payload.url,
      };
    }

    case DATA_EXPORT_ERROR: {
      return {
        status: "error",
        url: undefined,
      };
    }

    default:
      return state;
  }
};
