import { AnyActionI, APP_CLOSE_INTEGRATION_MODAL_ACTION, APP_OPEN_INTEGRATION_MODAL_ACTION, APP_SET_ACTIVATED_INTEGRATIONS, APP_SET_SELECTED_INTEGRATION } from "../constants"

type State = {
    intercom: boolean | undefined,
    selectedIntegration: {
        name: string,
        active: boolean,
    },
    modalIsOpen: boolean,
    haveBeenSet: boolean,
}

const initialState: State = {
    intercom: undefined,
    selectedIntegration: {
        name: "",
        active: false,
    },
    modalIsOpen: false,
    haveBeenSet: false,
}

export const integrationsReducer = (state = initialState, action: AnyActionI) => {
    switch(action.type) {
        case APP_SET_ACTIVATED_INTEGRATIONS:
            return {
                ...state,
                ...{...action.payload, ...{haveBeenSet: true}}
            };
        case APP_SET_SELECTED_INTEGRATION:
            return {
                ...state,
                ...{selectedIntegration: action.payload}
            }
        case APP_OPEN_INTEGRATION_MODAL_ACTION:
            return {
                ...state,
                ...{modalIsOpen: true}
            }
        case APP_CLOSE_INTEGRATION_MODAL_ACTION:
            return {
                ...state,
                ...{modalIsOpen: false}
            }
        default:
            return state
    }
}