import axios, { AxiosInstance } from "axios";
import { DEEPTALK_API_HOST } from "../constants";

class APIKit {
  instance: AxiosInstance = axios.create();
  create({ headers }: any) {
    this.instance = axios.create({
      baseURL: DEEPTALK_API_HOST,
      headers: {
        ...headers,
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    });

    return this.instance;
  }

  getInstance() {
    return this.instance;
  }
}

export default new APIKit();
