import {
  AnyDeeperActionI,
  APP_DEEPER_GET_LATEST_PREDICTIONS_SUCCESS_ACTION,
  APP_DEEPER_PREDICTION_SUCCESS_ACTION,
  APP_DEEPER_SET_EXAMPLES_ACTION,
  APP_DEEPER_SET_RESULTS,
  APP_DEEPER_SET_RESULT_FIELDS,
  APP_DEEPER_UNSET_CURRENT_PREDICTION,
  APP_DEEPER_UPDATE_SELECTED_DEEPER_STATUS_SUCCESS,
  APP_HIDE_DEEPER_CREATION_MODAL,
  APP_SET_DEEPERS_ACTION,
  APP_SET_SELECTED_DEEPER_ACTION,
  APP_SHOW_DEEPER_CREATION_MODAL,
  APP_SET_PRETRAINED_DEEPERS_ACTION,
} from "../constants";
import { Deeper, ExtendedDeeper } from "../dal/deepers";
import { PretrainedDeeper } from "../dal/library";

export type AppDeeperState = {
  list: Deeper[] | undefined;
  selectedDeeper: ExtendedDeeper | undefined;
  createModalShown: boolean;
  library: PretrainedDeeper[] | undefined;
};

const initialState: AppDeeperState = {
  list: undefined,
  selectedDeeper: undefined,
  createModalShown: false,
  library: undefined,
};

export const deeperReducer = (
  state = initialState,
  action: AnyDeeperActionI
) => {
  switch (action.type) {
    case APP_SET_PRETRAINED_DEEPERS_ACTION: {
      return {
        ...state,
        library: action.payload,
      };
    }
    case APP_SET_DEEPERS_ACTION: {
      return {
        ...state,
        list: action.payload,
      } as AppDeeperState;
    }

    case APP_SET_SELECTED_DEEPER_ACTION: {
      return {
        ...state,
        selectedDeeper: action.payload,
      } as AppDeeperState;
    }

    case APP_DEEPER_UPDATE_SELECTED_DEEPER_STATUS_SUCCESS: {
      const current = state.selectedDeeper;
      return {
        ...state,
        selectedDeeper: {
          ...current,
          name: action.payload.name,
          label: action.payload.label,
          threshold: action.payload.threshold,
          status: action.payload.status,
          last_analysed_at: action.payload.last_analysed_at,
          last_changed_at: action.payload.last_changed_at,
          count_detections: action.payload.count_detections,
          count_unique_documents: action.payload.count_unique_documents,
          pct_detections: action.payload.pct_detections,
        },
      } as AppDeeperState;
    }

    case APP_HIDE_DEEPER_CREATION_MODAL: {
      return {
        ...state,
        createModalShown: false,
      } as AppDeeperState;
    }

    case APP_SHOW_DEEPER_CREATION_MODAL: {
      return {
        ...state,
        createModalShown: true,
      } as AppDeeperState;
    }

    case APP_DEEPER_SET_EXAMPLES_ACTION: {
      const current = state.selectedDeeper;
      return {
        ...state,
        selectedDeeper: {
          ...current,
          annotations: action.payload.annotations,
        },
      } as AppDeeperState;
    }

    case APP_DEEPER_PREDICTION_SUCCESS_ACTION: {
      const current = state.selectedDeeper;
      return {
        ...state,
        selectedDeeper: {
          ...current,
          currentPrediction: action.payload,
        },
      } as AppDeeperState;
    }

    case APP_DEEPER_UNSET_CURRENT_PREDICTION: {
      const current = state.selectedDeeper;
      return {
        ...state,
        selectedDeeper: {
          ...current,
          currentPrediction: undefined,
        },
      } as AppDeeperState;
    }

    case APP_DEEPER_GET_LATEST_PREDICTIONS_SUCCESS_ACTION: {
      const current = state.selectedDeeper;
      return {
        ...state,
        selectedDeeper: {
          ...current,
          ...{
            latestPredictions: action.payload.segments,
            totalPageCount: action.payload.total_page_count,
          },
        },
      } as AppDeeperState;
    }

    case APP_DEEPER_SET_RESULTS: {
      const current = state.selectedDeeper;
      return {
        ...state,
        selectedDeeper: {
          ...current,
          results: action.payload,
        },
      } as AppDeeperState;
    }

    case APP_DEEPER_SET_RESULT_FIELDS: {
      const current = state.selectedDeeper;
      return {
        ...state,
        selectedDeeper: {
          ...current,
          fields: action.payload,
        },
      } as AppDeeperState;
    }

    default:
      return state;
  }
};
