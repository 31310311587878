import { Observable, Store } from "redux";
import {
  ActionsObservable,
  combineEpics,
  ofType,
  StateObservable,
} from "redux-observable";
import { from, of, Subject } from "rxjs";
import {
  catchError,
  ignoreElements,
  map,
  mapTo,
  mergeMap,
  switchMap,
  tap,
} from "rxjs/operators";
import APIKit from "../utils/ApiKit";
import { EpicDependencies } from ".";

import {
  AnyActionI,
  APP_UI_ADD_TOAST_ACTION,
  APP_WEBSOCKET_INIT_ACTION,
  APP_WEBSOCKET_MESSAGE_RECEIVED_ACTION,
  APP_WIZARD_SET_MODEL_COLUMN_MAPPING_ACTION,
  APP_WIZARD_UPDATE_ANALYSIS_BATCH_STATUS_ACTION,
  APP_WIZARD_UPDATE_MODEL_STATUS_ACTION,
  DATA_EXPORT_ERROR,
  DATA_EXPORT_SUCCESS,
  DEEPTALK_WEBSOCKET_HOST,
  WebsocketInitAction,
  WebsocketMessageReceivedAction,
  WSAnalysisBatchStatusUpdateEvents,
  WSMessage,
  WSModelStatusUpdateEvents,
  WSPresignedUrlCreatedEvent,
  WSPresignedUrlErrorEvent,
} from "../constants";
import { AppState } from "../reducers/interfaces";

import { webSocket, WebSocketSubject } from "rxjs/webSocket";
/*import {
  connected,
  connect,
  disconnected,
  disconnect,
  IConnectWebsocketAction,
  receiveMessageFromWebSocket,
  sentMessage,
  WebsocketActionTypes,
  IDisconnectFromWebsocketAction
} from '../actions/websocketActions';*/

let webSocketSubject: WebSocketSubject<{}>;
let onOpenSubject = new Subject();
let onCloseSubject = new Subject();

const connectSocket = ({ userId, token }: WebsocketInitAction["payload"]) => {
  onOpenSubject = new Subject();
  onCloseSubject = new Subject();
  webSocketSubject = webSocket({
    url: `${process.env.REACT_APP_DEEPTALK_WEBSOCKET_HOST}/${userId}/?token=${token}` as string,
    openObserver: onOpenSubject,
    closeObserver: onCloseSubject,
  });
  return webSocketSubject;
};

const initWebsocketEpic = (
  action$: ActionsObservable<AnyActionI>,
  state$: StateObservable<AppState>
) => {
  return action$.pipe(
    ofType(APP_WEBSOCKET_INIT_ACTION),
    switchMap((a) => {
      const action = a as WebsocketInitAction;
      const res = connectSocket(action.payload);

      return res.pipe(
        mergeMap((data) => [
          {
            type: APP_WEBSOCKET_MESSAGE_RECEIVED_ACTION,
            payload: data,
          } as AnyActionI,
        ]),
        catchError((err) =>
          of({
            type: "DEFAULT_EVENT",
            payload: {},
          })
        )
      );
    })
  );
};

const websocketReceiveMessageEpic = (
  action$: ActionsObservable<AnyActionI>,
  state$: StateObservable<AppState>
) => {
  return action$.pipe(
    ofType(APP_WEBSOCKET_MESSAGE_RECEIVED_ACTION),
    mergeMap((a) => {
      const action = a as WebsocketMessageReceivedAction;
      const messageType = action.payload.message.type;

      switch (messageType) {
        case "status.update":
          const pl = action.payload as WSAnalysisBatchStatusUpdateEvents;
          if (pl.message.payload.object_type !== "analysis_batch") {
            return [];
          }
          return [
            {
              type: APP_WIZARD_UPDATE_ANALYSIS_BATCH_STATUS_ACTION,
              payload: pl,
            } as AnyActionI,
          ];
        case "data_export.success":
          const pl2 = action.payload as WSPresignedUrlCreatedEvent;
          return [
            {
              type: DATA_EXPORT_SUCCESS,
              payload: {
                url: pl2.message.payload.presigned_url,
              },
            },
          ];
        case "data_export.error":
          return [
            {
              type: DATA_EXPORT_ERROR,
            },
          ];
        default:
          return [];
      }
    })
  );
};

/*const connectedEpic = (action$: ActionsObservable<AnyAction>) => {
  return action$.ofType(WebsocketActionTypes.CONNECT).switchMap(() =>
    onOpenSubject.map(() => {
      onCloseSubject.map(() => disconnect(true));
      return connected();
    })
  );
}*/

/*
const sendMessageEpic = action$ =>
  action$.ofType(WebsocketActionTypes.SEND_MESSAGE).map(action => {
    webSocketSubject.next(action.payload);
    return sentMessage();
  });
*/

/*const disconnectEpic = (action$, state$: StateObservable<IState>) =>
  action$
    .ofType(WebsocketActionTypes.DISCONNECT)
    .mergeMap((action: IDisconnectFromWebsocketAction) => {
      if (action.payload.retry) {
        return of(connect(state$.value.config.webSocketURL))
          .delay(5000)
          .startWith(disconnected());
      }
      onCloseSubject.complete();
      webSocketSubject.complete();
      return [disconnected()];
    });*/

export const websocketEpic = combineEpics(
  initWebsocketEpic,
  websocketReceiveMessageEpic
);
