import axios from "axios";
import { Observable, Store } from "redux";
import { ActionsObservable, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { EpicDependencies } from ".";

import {
  APP_LOGIN_REQUEST_ACTION,
  APP_LOGIN_SUCCESS_ACTION,
  APP_LOGIN_ERROR_ACTION,
  DEEPTALK_API_HOST,
} from "../constants";

const loginRequest = (username: string, password: string) => {
  return axios.post(`${DEEPTALK_API_HOST}/auth/user`, {
    username,
    password,
  });
};

const authEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_LOGIN_REQUEST_ACTION),
    mergeMap((action) =>
      from(loginRequest(action.payload.username, action.payload.password)).pipe(
        map((response) => ({
          type: APP_LOGIN_SUCCESS_ACTION,
          payload: response.data,
        })),
        catchError((error) =>
          of({ type: APP_LOGIN_ERROR_ACTION, payload: error.message })
        )
      )
    )
  );
};

export { authEpic };
