import { combineEpics, ofType, StateObservable } from "redux-observable";
import { from, Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import {
  APP_CLEAR_METADATA_FILTER_SELECTION_ACTION,
  APP_CLEAR_METADATA_SUMMARY_ACTION,
  APP_CLEAR_SELECTED_CATEGORY_ACTION,
  APP_CLEAR_SELECTED_ENTITY_ACTION,
  APP_CLEAR_SELECTED_PLAYER_ACTION,
  APP_CLEAR_SELECTED_SENTIMENT_ACTION,
  APP_CLEAR_SELECTED_TOPIC_ACTION,
  APP_GET_AND_SET_CURRENT_PROJECT_ACTION,
  APP_GET_METADATA_SUMMARY_REQUEST_ACTION,
  APP_GET_MODEL_BATCHES_ACTION,
  APP_GET_MODEL_SETTINGS_ACTION,
  APP_GET_NUMBER_TOPIC_SEGMENTS_REQUEST,
  APP_GET_SUNBURST_DATA,
  APP_GET_TOPIC_GROUP_MEMBERSHIPS_ACTION,
  APP_GET_TOPIC_TIME_EVOLUTION_DATA,
  APP_PREPARE_DASHBOARD_ACTION,
  APP_SET_SELECTED_MODEL_ID_ACTION,
  APP_SET_SELECTED_MODEL_RESULT_DETAIL_ACTION,
  APP_SET_SELECTED_TOPIC_ACTION,
  APP_SET_TEXT_QUERY_ACTION,
  APP_SET_TOPIC_TIME_EVOLUTION_DATA,
} from "../constants";
import { getResultDetailReq, getTopicTimeEvolutionReq } from "../dal";
import { ActionWithPayload, AppState } from "../reducers/interfaces";

const prepareDashboardEpic = (
  action$: any,
  state$: StateObservable<AppState>
) => {
  return action$.pipe(
    ofType(APP_PREPARE_DASHBOARD_ACTION),
    mergeMap((act: ActionWithPayload<any>) => [
      {
        type: APP_SET_SELECTED_MODEL_ID_ACTION,
        payload: {
          model_id: act.payload.modelId,
        },
      },
      {
        type: APP_GET_SUNBURST_DATA,
        payload: {
          modelId: act.payload.modelId,
          min_topic_size: 3,
        },
      },
      {
        type: APP_GET_AND_SET_CURRENT_PROJECT_ACTION,
        payload: { projectId: act.payload.projectId },
      },
      {
        type: APP_GET_MODEL_SETTINGS_ACTION,
        payload: { modelId: act.payload.modelId },
      },
      {
        type: APP_GET_MODEL_BATCHES_ACTION,
        payload: { modelId: act.payload.modelId },
      },
      {
        type: APP_GET_NUMBER_TOPIC_SEGMENTS_REQUEST,
        payload: { modelId: act.payload.modelId },
      },
      {
        type: APP_SET_TEXT_QUERY_ACTION,
        payload: { query: undefined },
      },
      {
        type: APP_GET_METADATA_SUMMARY_REQUEST_ACTION,
        payload: { modelId: act.payload.modelId },
      },
      { type: APP_CLEAR_SELECTED_SENTIMENT_ACTION },
      { type: APP_CLEAR_SELECTED_TOPIC_ACTION },
      { type: APP_CLEAR_SELECTED_CATEGORY_ACTION },
      { type: APP_CLEAR_SELECTED_ENTITY_ACTION },
      { type: APP_CLEAR_SELECTED_PLAYER_ACTION },
      { type: APP_CLEAR_METADATA_FILTER_SELECTION_ACTION },
    ])
  );
};

// const selectTopicEpic = (action$: any, state$: StateObservable<AppState>) => {
//   return action$.pipe(
//     ofType(APP_SET_SELECTED_TOPIC_ACTION),
//     mergeMap((act: ActionWithPayload<any>) => {
//       const modelId = state$.value.modelResults.selectedModelId as number;
//       const topicId = act.payload.topic;
//       const level = act.payload.level;
//       if (!modelId) {
//         return new Observable();
//       }
//       if (!topicId) {
//         return new Observable();
//       }
//       return from(getResultDetailReq(modelId, topicId, level)).pipe(
//         mergeMap((res) => [
//           {
//             type: APP_SET_SELECTED_MODEL_RESULT_DETAIL_ACTION,
//             payload: { result_detail: res.data },
//           },
//           {
//             type: APP_GET_TOPIC_GROUP_MEMBERSHIPS_ACTION,
//             payload: { model_id: modelId, topic_id: topicId },
//           },
//         ])
//       );
//     })
//   );
// };

const getTopicTimeEvolutionData = (
  action$: any,
  state$: StateObservable<AppState>
) => {
  return action$.pipe(
    ofType(APP_GET_TOPIC_TIME_EVOLUTION_DATA),
    mergeMap((act: ActionWithPayload<any>) => {
      const topicId = act.payload["topicId"] as string;
      const modelId = act.payload["modelId"] as number;
      const level = act.payload["level"] as number;
      return from(getTopicTimeEvolutionReq(modelId, topicId, level)).pipe(
        mergeMap((res) => [
          {
            type: APP_SET_TOPIC_TIME_EVOLUTION_DATA,
            payload: { topic_time_evolution: res.data },
          },
        ])
      );
    })
  );
};

export const modelResultEpic = combineEpics(
  // selectTopicEpic,
  getTopicTimeEvolutionData,
  prepareDashboardEpic
);
