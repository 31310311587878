import { Observable, Store } from "redux";
import { ActionsObservable, combineEpics, ofType } from "redux-observable";
import { EpicDependencies } from ".";
import { from, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import {
  APP_DEEPERS_ERROR_ACTION,
  APP_DEEPER_ADD_EXAMPLE_REQUEST,
  APP_DEEPER_ADD_EXAMPLE_SUCCESS,
  APP_DEEPER_ADD_EXAMPLE_ERROR,
  APP_DEEPER_GET_EXAMPLES_ACTION,
  APP_DEEPER_GET_LATEST_PREDICTIONS_ERROR_ACTION,
  APP_DEEPER_GET_LATEST_PREDICTIONS_REQUEST_ACTION,
  APP_DEEPER_GET_LATEST_PREDICTIONS_SUCCESS_ACTION,
  APP_DEEPER_PREDICTION_ERROR_ACTION,
  APP_DEEPER_PREDICTION_REQUEST_ACTION,
  APP_DEEPER_PREDICTION_SUCCESS_ACTION,
  APP_DEEPER_SET_EXAMPLES_ACTION,
  APP_DEEPER_TAG_EXAMPLE_ACTION,
  APP_DEEPER_UPDATE_REQUEST,
  APP_DEEPER_UPDATE_SUCCESS,
  APP_GET_DEEPERS_ACTION,
  APP_SAVE_DEEPER_REQUEST,
  APP_SET_DEEPERS_ACTION,
  APP_UI_ADD_TOAST_ACTION,
  DEEPER_API_HOST,
  APP_DEEPER_GET_RESULTS_REQUEST,
  APP_DEEPER_GET_RESULTS_SUCCESS,
  APP_DEEPER_GET_RESULTS_ERROR,
  APP_DEEPER_SET_RESULTS,
  APP_DEEPER_UPDATE_SELECTED_DEEPER_STATUS_REQUEST,
  APP_DEEPER_UPDATE_SELECTED_DEEPER_STATUS_SUCCESS,
  APP_DEEPER_UPDATE_SELECTED_DEEPER_STATUS_ERROR,
  APP_DEEPER_PREDICT_FILE_REQUEST,
  APP_DEEPER_PREDICT_FILE_SUCCESS,
  APP_GET_SELECTED_DEEPER_ACTION,
  APP_SET_SELECTED_DEEPER_ACTION,
  APP_DEEPER_REMOVE_RESULT_REQUEST,
  APP_DEEPER_REMOVE_RESULT_SUCCESS,
  APP_DEEPER_TAG_PREDICTION_REQUEST,
  APP_DEEPER_DELETE_DEEPER_REQUEST,
  APP_DEEPER_TAG_PREDICTION_SUCCESS,
  APP_DEEPER_TAG_PREDICTION_ERROR,
  APP_DEEPER_TAG_RESULT_REQUEST,
  APP_DEEPER_TAG_RESULT_SUCCESS,
  APP_DEEPER_TAG_RESULT_ERROR,
  APP_PAYMENT_REQUIRED_ACTION,
  APP_SAVE_DEEPER_ERROR,
  APP_SAVE_DEEPER_SUCCESS,
  APP_GET_AND_SET_CURRENT_PROJECT_ACTION,
  APP_GET_PROJECTS_REQUEST_ACTION,
  APP_PREPARE_DEEPER_SETTINGS_ACTION,
  APP_GET_PRETRAINED_DEEPERS_ACTION,
  APP_SET_PRETRAINED_DEEPERS_ACTION,
} from "../constants";
import ApiKit from "../utils/ApiKit";
import { ActionWithPayload } from "../reducers/interfaces";
import { execGetPretrainedDeepers } from "../dal/library";

const getDeepersRequest = () => {
  return ApiKit.getInstance().get(`${DEEPER_API_HOST}/deeper`);
};

const getDeeperDetailRequest = (payload: { deeper_id: string }) => {
  return ApiKit.getInstance().get(
    `${DEEPER_API_HOST}/deeper/${payload.deeper_id}`
  );
};

const updateDeeperRequest = (payload: {
  deeper_id: string;
  name: string;
  label: string;
  threshold: number;
}) => {
  return ApiKit.getInstance().patch(
    `${DEEPER_API_HOST}/deeper/${payload.deeper_id}`,
    { name: payload.name, label: payload.label, threshold: payload.threshold }
  );
};

const createDeepersRequest = (payload: any) => {
  return ApiKit.getInstance().post(
    `${DEEPER_API_HOST}/project/${payload.project}/deeper`,
    payload
  );
};

const deleteDeeperRequest = (payload: { deeper_id: string }) => {
  return ApiKit.getInstance().delete(
    `${DEEPER_API_HOST}/deeper/${payload.deeper_id}`
  );
};

const addExampleRequest = (payload: {
  deeper_id: string;
  content: string;
  value: number;
}) => {
  const val = payload.value === undefined ? 1 : payload.value;
  return ApiKit.getInstance().post(
    `${DEEPER_API_HOST}/deeper/${payload.deeper_id}/train`,
    { content: payload.content, value: val }
  );
};

const predictExampleRequest = (payload: {
  deeper_id: string;
  content: string;
}) => {
  return ApiKit.getInstance().post(
    `${DEEPER_API_HOST}/zapier/actions?deeper=${payload.deeper_id}&actions-type=item`,
    {
      content: payload.content,
      // author: "me",
      // source: "Deep Talk",
      external_reference: "test",
    }
  );
};

const tagExampleRequest = (payload: {
  deeper_id: string;
  annotation_id: string;
  value: number;
}) => {
  return ApiKit.getInstance().patch(
    `${DEEPER_API_HOST}/deeper/${payload.deeper_id}/train/${payload.annotation_id}`,
    { value: payload.value }
  );
};

const tagPredictionRequest = (payload: {
  deeper_id: string;
  prediction_id: number;
  value: boolean;
}) => {
  return ApiKit.getInstance().patch(
    `${DEEPER_API_HOST}/deeper/${payload.deeper_id}/tag_prediction/${payload.prediction_id}`,
    { value: payload.value }
  );
};

const tagResultRequest = (payload: {
  deeper_id: string;
  result_id: number;
  value: boolean;
}) => {
  return ApiKit.getInstance().patch(
    `${DEEPER_API_HOST}/deeper/${payload.deeper_id}/tag_result/${payload.result_id}`,
    { value: payload.value }
  );
};

const getExamplesRequest = (payload: { deeper_id: string }) => {
  return ApiKit.getInstance().get(
    `${DEEPER_API_HOST}/deeper/${payload.deeper_id}/deeper_annotation`
  );
};

const getLatestPredictionsRequest = (payload: {
  deeper_id: string;
  page_number: number;
  listen_to: string;
}) => {
  return ApiKit.getInstance().get(
    `${DEEPER_API_HOST}/deeper/${payload.deeper_id}/prediction?page_number=${payload.page_number}&listen_to=${payload.listen_to}`
  );
};

const predictFileRequest = (payload: { deeper_id: string }) => {
  return ApiKit.getInstance().post(
    `${DEEPER_API_HOST}/deeper/${payload.deeper_id}/predict_file`
  );
};

const getDeeperResultRequest = (payload: {
  deeper_id: string;
  pageNumber: number;
  itemsPerPage: number;
}) => {
  const pn = payload.pageNumber ? payload.pageNumber : 0;
  const ipp = payload.itemsPerPage ? payload.itemsPerPage : 20;
  return ApiKit.getInstance().get(
    `${DEEPER_API_HOST}/deeper/${payload.deeper_id}/result?page_number=${pn}&items_per_page=${ipp}`
  );
};

const getDeepersEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_GET_DEEPERS_ACTION),
    mergeMap(() =>
      from(getDeepersRequest()).pipe(
        map((response) => ({
          type: APP_SET_DEEPERS_ACTION,
          payload: response.data,
        })),
        catchError((error) =>
          of({
            type: APP_DEEPERS_ERROR_ACTION,
            payload: error.message,
          })
        )
      )
    )
  );
};

const getCurrentDeeperEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_DEEPER_UPDATE_SELECTED_DEEPER_STATUS_REQUEST),
    mergeMap((action) =>
      from(getDeeperDetailRequest(action.payload)).pipe(
        map((response) => ({
          type: APP_DEEPER_UPDATE_SELECTED_DEEPER_STATUS_SUCCESS,
          payload: response.data,
        })),
        catchError((error) =>
          of({
            type: APP_DEEPER_UPDATE_SELECTED_DEEPER_STATUS_ERROR,
            payload: error.message,
          })
        )
      )
    )
  );
};

const saveDeeperEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_SAVE_DEEPER_REQUEST),
    mergeMap((action) =>
      from(createDeepersRequest(action.payload)).pipe(
        mergeMap((response) => [
          { type: APP_GET_DEEPERS_ACTION },
          { type: APP_SAVE_DEEPER_SUCCESS },
        ]),
        catchError((error) => {
          if (error.response.status === 402) {
            return from([
              {
                type: APP_UI_ADD_TOAST_ACTION,
                payload: {
                  id: APP_PAYMENT_REQUIRED_ACTION,
                  type: "payment",
                  text: "To create more Deepers, please subscribe to a higher plan.",
                },
              },
              { type: APP_SAVE_DEEPER_ERROR, payload: error.message },
            ]);
          } else {
            return from([
              {
                type: APP_UI_ADD_TOAST_ACTION,
                payload: {
                  id: APP_SAVE_DEEPER_REQUEST,
                  type: "error",
                  text: "Something went wrong when creating the deeper.",
                },
              },
              { type: APP_SAVE_DEEPER_ERROR, payload: error.message },
            ]);
          }
        })
      )
    )
  );
};

const addDeeperExampleEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_DEEPER_ADD_EXAMPLE_REQUEST),
    mergeMap((action) =>
      from(addExampleRequest(action.payload)).pipe(
        mergeMap((response) => [
          { type: APP_DEEPER_ADD_EXAMPLE_SUCCESS },
          {
            type: APP_DEEPER_GET_EXAMPLES_ACTION,
            payload: { deeper_id: action.payload.deeper_id },
          },
          {
            type: APP_DEEPER_UPDATE_SELECTED_DEEPER_STATUS_REQUEST,
            payload: action.payload,
          },
        ]),
        catchError((error) => {
          if (error.response.status === 551) {
            return from([
              {
                type: APP_UI_ADD_TOAST_ACTION,
                payload: {
                  id: APP_DEEPER_ADD_EXAMPLE_REQUEST,
                  type: "error",
                  text: "No similar sentences were found in your dataset. Please try again with a different example.",
                },
              },
              { type: APP_DEEPER_ADD_EXAMPLE_ERROR, payload: error.message },
            ]);
          } else {
            return from([
              {
                type: APP_UI_ADD_TOAST_ACTION,
                payload: {
                  id: APP_DEEPER_ADD_EXAMPLE_REQUEST,
                  type: "error",
                  text: "Something went wrong when adding the example to the Deeper.",
                },
              },
              { type: APP_DEEPER_ADD_EXAMPLE_ERROR, payload: error.message },
            ]);
          }
        })
      )
    )
  );
};

const tagDeeperExampleEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_DEEPER_TAG_EXAMPLE_ACTION),
    mergeMap((action) =>
      from(tagExampleRequest(action.payload)).pipe(
        mergeMap((response) => [
          {
            type: APP_DEEPER_GET_EXAMPLES_ACTION,
            payload: { deeper_id: action.payload.deeper_id },
          },
          {
            type: APP_DEEPER_UPDATE_SELECTED_DEEPER_STATUS_REQUEST,
            payload: action.payload,
          },
        ]),
        catchError((error) =>
          from([
            {
              type: APP_UI_ADD_TOAST_ACTION,
              payload: {
                id: APP_DEEPER_TAG_EXAMPLE_ACTION,
                type: "error",
                text: "Something went wrong when tagging the example.",
              },
            },
            { type: APP_DEEPERS_ERROR_ACTION, payload: error.message },
          ])
        )
      )
    )
  );
};

const getDeeperExamplesEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_DEEPER_GET_EXAMPLES_ACTION),
    mergeMap((action) =>
      from(getExamplesRequest(action.payload)).pipe(
        mergeMap((response) => [
          {
            type: APP_DEEPER_SET_EXAMPLES_ACTION,
            payload: { annotations: response.data },
          },
        ]),
        catchError((error) =>
          from([
            {
              type: APP_UI_ADD_TOAST_ACTION,
              payload: {
                id: APP_DEEPER_GET_EXAMPLES_ACTION,
                type: "error",
                text: "Something went wrong when loadig the Deeper's examples.",
              },
            },
            { type: APP_DEEPERS_ERROR_ACTION, payload: error.message },
          ])
        )
      )
    )
  );
};

const predictDeeperEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_DEEPER_PREDICTION_REQUEST_ACTION),
    mergeMap((action) =>
      from(predictExampleRequest(action.payload)).pipe(
        mergeMap((response) => [
          {
            type: APP_DEEPER_PREDICTION_SUCCESS_ACTION,
            payload: response.data,
          },
          {
            type: APP_DEEPER_GET_LATEST_PREDICTIONS_REQUEST_ACTION,
            payload: {
              deeper_id: action.payload.deeper_id,
              listen_to: action.payload.listen_to,
            },
          },
        ]),
        catchError((error) =>
          from([
            {
              type: APP_UI_ADD_TOAST_ACTION,
              payload: {
                id: APP_DEEPER_PREDICTION_REQUEST_ACTION,
                type: "error",
                text: "Something went wrong when predicting the example.",
              },
            },
            {
              type: APP_DEEPER_PREDICTION_ERROR_ACTION,
              payload: error.message,
            },
          ])
        )
      )
    )
  );
};

const getLatestPredictionsDeeperEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_DEEPER_GET_LATEST_PREDICTIONS_REQUEST_ACTION),
    mergeMap((action) =>
      from(getLatestPredictionsRequest(action.payload)).pipe(
        mergeMap((response) => [
          {
            type: APP_DEEPER_GET_LATEST_PREDICTIONS_SUCCESS_ACTION,
            payload: response.data,
          },
        ]),
        catchError((error) =>
          from([
            {
              type: APP_UI_ADD_TOAST_ACTION,
              payload: {
                id: APP_DEEPER_GET_LATEST_PREDICTIONS_REQUEST_ACTION,
                type: "error",
                text: "Something went wrong when getting the latest predictions.",
              },
            },
            {
              type: APP_DEEPER_GET_LATEST_PREDICTIONS_ERROR_ACTION,
              payload: error.message,
            },
          ])
        )
      )
    )
  );
};

const updateDeeperEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_DEEPER_UPDATE_REQUEST),
    mergeMap((action) =>
      from(updateDeeperRequest(action.payload)).pipe(
        mergeMap((response) => [
          { type: APP_DEEPER_UPDATE_SUCCESS },
          { type: APP_GET_DEEPERS_ACTION },
          {
            type: APP_DEEPER_UPDATE_SELECTED_DEEPER_STATUS_REQUEST,
            payload: action.payload,
          },
        ]),
        catchError((error) =>
          from([
            {
              type: APP_UI_ADD_TOAST_ACTION,
              payload: {
                id: APP_DEEPER_GET_LATEST_PREDICTIONS_REQUEST_ACTION,
                type: "error",
                text: "Something went wrong when updating the Deeper.",
              },
            },
            {
              type: APP_DEEPER_GET_LATEST_PREDICTIONS_ERROR_ACTION,
              payload: error.message,
            },
          ])
        )
      )
    )
  );
};

const deleteDeeperEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_DEEPER_DELETE_DEEPER_REQUEST),
    mergeMap((action) =>
      from(deleteDeeperRequest(action.payload)).pipe(
        mergeMap((response) => [{ type: APP_GET_DEEPERS_ACTION }]),
        catchError((error) =>
          from([
            {
              type: APP_UI_ADD_TOAST_ACTION,
              payload: {
                id: APP_DEEPER_DELETE_DEEPER_REQUEST,
                type: "error",
                text: "Something went wrong when deleting the Deeper.",
              },
            },
          ])
        )
      )
    )
  );
};

const getResultsEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_DEEPER_GET_RESULTS_REQUEST),
    mergeMap((action) =>
      from(getDeeperResultRequest(action.payload)).pipe(
        mergeMap((response) => [
          { type: APP_DEEPER_SET_RESULTS, payload: response.data },
          { type: APP_DEEPER_GET_RESULTS_SUCCESS },
        ]),
        catchError((error) =>
          from([
            {
              type: APP_UI_ADD_TOAST_ACTION,
              payload: {
                id: APP_DEEPER_GET_RESULTS_REQUEST,
                type: "error",
                text: "Something went wrong getting the results.",
              },
            },
            { type: APP_DEEPER_GET_RESULTS_ERROR, payload: error.message },
          ])
        )
      )
    )
  );
};

const predictFileEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_DEEPER_PREDICT_FILE_REQUEST),
    mergeMap((action) =>
      from(predictFileRequest(action.payload)).pipe(
        mergeMap((response) => [
          { type: APP_DEEPER_PREDICT_FILE_SUCCESS },
          { type: APP_DEEPER_GET_RESULTS_REQUEST, payload: action.payload },
          {
            type: APP_DEEPER_UPDATE_SELECTED_DEEPER_STATUS_REQUEST,
            payload: action.payload,
          },
        ]),
        catchError((error) =>
          from([
            {
              type: APP_UI_ADD_TOAST_ACTION,
              payload: {
                id: APP_DEEPER_GET_RESULTS_REQUEST,
                type: "error",
                text: "Something went wrong creating the detections.",
              },
            },
            { type: APP_DEEPER_GET_RESULTS_ERROR, payload: error.message },
          ])
        )
      )
    )
  );
};

const getSelectedDeeperEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_GET_SELECTED_DEEPER_ACTION),
    mergeMap((action) =>
      from(getDeeperDetailRequest(action.payload)).pipe(
        map((response) => ({
          type: APP_SET_SELECTED_DEEPER_ACTION,
          payload: response.data,
        })),
        catchError((error) =>
          of({
            type: APP_DEEPER_UPDATE_SELECTED_DEEPER_STATUS_ERROR,
            payload: error.message,
          })
        )
      )
    )
  );
};

const removeDeeperResultEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_DEEPER_REMOVE_RESULT_REQUEST),
    mergeMap((action) =>
      from(addExampleRequest(action.payload)).pipe(
        mergeMap((response) => [
          {
            type: APP_DEEPER_GET_EXAMPLES_ACTION,
            payload: { deeper_id: action.payload.deeper_id },
          },
          // { type: APP_DEEPER_PREDICT_FILE_REQUEST, payload: action.payload },
          { type: APP_DEEPER_REMOVE_RESULT_SUCCESS },
          {
            type: APP_DEEPER_UPDATE_SELECTED_DEEPER_STATUS_REQUEST,
            payload: action.payload,
          },
        ]),
        catchError((error) =>
          from([
            {
              type: APP_UI_ADD_TOAST_ACTION,
              payload: {
                id: APP_DEEPER_REMOVE_RESULT_REQUEST,
                type: "error",
                text: "Something went wrong when deleting the result.",
              },
            },
            { type: APP_DEEPER_ADD_EXAMPLE_ERROR, payload: error.message },
          ])
        )
      )
    )
  );
};

const tagDeeperLivePredictionEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_DEEPER_TAG_PREDICTION_REQUEST),
    mergeMap((action) =>
      from(tagPredictionRequest(action.payload)).pipe(
        mergeMap((response) => [
          {
            type: APP_DEEPER_GET_EXAMPLES_ACTION,
            payload: { deeper_id: action.payload.deeper_id },
          },
          {
            type: APP_DEEPER_GET_LATEST_PREDICTIONS_REQUEST_ACTION,
            payload: {
              deeper_id: action.payload.deeper_id,
              listen_to: action.payload.listen_to,
            },
          },
          {
            type: APP_DEEPER_UPDATE_SELECTED_DEEPER_STATUS_REQUEST,
            payload: action.payload,
          },
          {
            type: APP_DEEPER_TAG_PREDICTION_SUCCESS,
          },
        ]),
        catchError((error) =>
          from([
            {
              type: APP_UI_ADD_TOAST_ACTION,
              payload: {
                id: APP_DEEPER_TAG_PREDICTION_REQUEST,
                type: "error",
                text: "Something went wrong when annotating the live prediction.",
              },
            },
            { type: APP_DEEPER_TAG_PREDICTION_ERROR, payload: error.message },
          ])
        )
      )
    )
  );
};

const tagDeeperResultEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_DEEPER_TAG_RESULT_REQUEST),
    mergeMap((action) =>
      from(tagResultRequest(action.payload)).pipe(
        mergeMap((response) => [
          {
            type: APP_DEEPER_GET_EXAMPLES_ACTION,
            payload: { deeper_id: action.payload.deeper_id },
          },
          {
            type: APP_DEEPER_UPDATE_SELECTED_DEEPER_STATUS_REQUEST,
            payload: action.payload,
          },
          { type: APP_DEEPER_GET_RESULTS_REQUEST, payload: action.payload },
          {
            type: APP_DEEPER_TAG_RESULT_SUCCESS,
          },
        ]),
        catchError((error) =>
          from([
            {
              type: APP_UI_ADD_TOAST_ACTION,
              payload: {
                id: APP_DEEPER_TAG_RESULT_REQUEST,
                type: "error",
                text: "Something went wrong when annotating the results.",
              },
            },
            { type: APP_DEEPER_TAG_RESULT_ERROR, payload: error.message },
          ])
        )
      )
    )
  );
};

const prepareDeeperSettingsEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_PREPARE_DEEPER_SETTINGS_ACTION),
    mergeMap((act: ActionWithPayload<any>) => [
      {
        type: APP_GET_AND_SET_CURRENT_PROJECT_ACTION,
        payload: {
          projectId: act.payload.projectId,
        },
      },
      // {
      //   type: APP_GET_PROJECTS_REQUEST_ACTION,
      // },
    ])
  );
};

const getPretrainedDeepersEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_GET_PRETRAINED_DEEPERS_ACTION),
    mergeMap((action) =>
      from(execGetPretrainedDeepers(action.payload)).pipe(
        map((fmtResponse) => ({
          type: APP_SET_PRETRAINED_DEEPERS_ACTION,
          payload: fmtResponse,
        })),
        catchError((error) => of({}))
      )
    )
  );
};

export const deeperEpic = combineEpics(
  getDeepersEpic,
  getCurrentDeeperEpic,
  saveDeeperEpic,
  addDeeperExampleEpic,
  tagDeeperExampleEpic,
  getDeeperExamplesEpic,
  predictDeeperEpic,
  getLatestPredictionsDeeperEpic,
  updateDeeperEpic,
  getResultsEpic,
  predictFileEpic,
  getSelectedDeeperEpic,
  removeDeeperResultEpic,
  tagDeeperLivePredictionEpic,
  deleteDeeperEpic,
  tagDeeperResultEpic,
  prepareDeeperSettingsEpic,
  getPretrainedDeepersEpic
);
