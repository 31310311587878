import { Observable, Store } from "redux";
import { ActionsObservable, combineEpics, ofType } from "redux-observable";
import { forkJoin, from, of } from "rxjs";
import {
  catchError,
  concatMap,
  ignoreElements,
  map,
  mergeMap,
  tap,
} from "rxjs/operators";
import APIKit from "../utils/ApiKit";
import { EpicDependencies } from ".";

import {
  APP_CREATE_REPORT_ACTION,
  APP_GET_REPORT_DETAIL_ACTION,
  APP_GET_REPORTS_ACTION,
  APP_SET_REPORT_DETAIL_ACTION,
  APP_SET_REPORTS_ACTION,
  APP_UI_ADD_TOAST_ACTION,
  DEEPTALK_API_HOST,
} from "../constants";
import {
  Insight,
  Report,
  ReportCreateReqData,
  SaveInsightsReqPayload,
  UpdateInsightsReqPayload,
} from "../dal/reports";
import { ActionWithPayload } from "../reducers/interfaces";

const getReportsRequest = () => {
  return APIKit.getInstance().get<Report[]>(`${DEEPTALK_API_HOST}/reports/`);
};

const getReportDetailRequest = (report_id: string) => {
  return APIKit.getInstance().get<Report[]>(
    `${DEEPTALK_API_HOST}/reports/${report_id}/`
  );
};

export const saveReportsRequest = (data: ReportCreateReqData) => {
  return APIKit.getInstance().post<Report>(
    `${DEEPTALK_API_HOST}/reports/`,
    data
  );
};

export const saveInsightsRequest = (data: SaveInsightsReqPayload) => {
  return APIKit.getInstance().post<Insight>(
    `${DEEPTALK_API_HOST}/insights/`,
    data
  );
};

export const updateInsightsRequest = (
  insightId: string,
  data: UpdateInsightsReqPayload
) => {
  return APIKit.getInstance().patch<Insight>(
    `${DEEPTALK_API_HOST}/insights/${insightId}/`,
    data
  );
};

export const deleteInsightRequest = (insightId: string) => {
  return APIKit.getInstance().delete(
    `${DEEPTALK_API_HOST}/insights/${insightId}/`
  );
};

const getReportsEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_GET_REPORTS_ACTION),
    mergeMap(() =>
      from(getReportsRequest()).pipe(
        map((response) => ({
          type: APP_SET_REPORTS_ACTION,
          payload: response.data,
        })),
        catchError((error) =>
          of({
            type: APP_UI_ADD_TOAST_ACTION,
            payload: {
              id: APP_GET_REPORTS_ACTION,
              type: "error",
              text: "Could not get reports",
              duration: 3500,
            },
          })
        )
      )
    )
  );
};

const getReportDetailEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_GET_REPORT_DETAIL_ACTION),
    mergeMap((action: ActionWithPayload<any>) =>
      from(getReportDetailRequest(action.payload.report_id)).pipe(
        map((response) => ({
          type: APP_SET_REPORT_DETAIL_ACTION,
          payload: response.data,
        })),
        catchError((error) =>
          of({
            type: APP_UI_ADD_TOAST_ACTION,
            payload: {
              id: APP_GET_REPORTS_ACTION,
              type: "error",
              text: "Could not get report details",
              duration: 3500,
            },
          })
        )
      )
    )
  );
};

const saveReportEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_CREATE_REPORT_ACTION),
    mergeMap((action: ActionWithPayload<any>) =>
      from(saveReportsRequest(action.payload)).pipe(
        mergeMap((response) => [{ type: APP_GET_REPORTS_ACTION }]),
        catchError((error) => {
          if (error.response.status === 402) {
            return from([
              {
                type: APP_UI_ADD_TOAST_ACTION,
                payload: {
                  id: APP_GET_REPORTS_ACTION,
                  type: "error",
                  text: "Could not create report",
                  duration: 3500,
                },
              },
            ]);
          } else {
            return from([
              {
                type: APP_UI_ADD_TOAST_ACTION,
                payload: {
                  id: APP_GET_REPORTS_ACTION,
                  type: "error",
                  text: "Could not create report",
                  duration: 3500,
                },
              },
            ]);
          }
        })
      )
    )
  );
};

export const reportsEpic = combineEpics(
  getReportsEpic,
  saveReportEpic,
  getReportDetailEpic
  // getAndSetCurrentProjectsAnalyses
);
