import {
  AnyAiModuleActionI,
  APP_SET_AI_MODULE_DETAIL_ACTION,
  APP_SET_AI_MODULES_ACTION,
} from "../constants";
import { AiModule } from "../dal/ai_modules";

export type AppAiModulesState = {
  list: AiModule[];
  detail?: AiModule;
};

const initialState: AppAiModulesState = {
  list: [],
  detail: undefined,
};

export const aiModulesReducer = (
  state = initialState,
  action: AnyAiModuleActionI
) => {
  switch (action.type) {
    case APP_SET_AI_MODULES_ACTION: {
      const reports = action.payload;
      return {
        ...state,
        list: reports,
      } as AppAiModulesState;
    }

    case APP_SET_AI_MODULE_DETAIL_ACTION: {
      const module_detail = action.payload;
      return {
        ...state,
        detail: module_detail,
      } as AppAiModulesState;
    }

    default:
      return state as AppAiModulesState;
  }
};
