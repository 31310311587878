import { Store } from "redux";
import { ActionsObservable, combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import {
  APP_GET_AND_SET_CURRENT_PROJECT_ACTION,
  APP_GET_MODEL_BATCHES_ACTION,
  APP_GET_MODEL_SETTINGS_ACTION,
  APP_GET_PROJECTS_REQUEST_ACTION,
  APP_PREPARE_ANALYSIS_SETTINGS_ACTION,
  APP_SET_SELECTED_MODEL_ID_ACTION,
  APP_GET_NUMBER_TOPIC_SEGMENTS_REQUEST,
  APP_GET_BILLING_INFORMATION_REQUEST,
} from "../../constants";
import { ActionWithPayload } from "../../reducers/interfaces";
import { EpicDependencies } from "../index";

const prepareAnalysisSettingsEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_PREPARE_ANALYSIS_SETTINGS_ACTION),
    mergeMap((act: ActionWithPayload<any>) => [
      {
        type: APP_GET_BILLING_INFORMATION_REQUEST,
      },
      {
        type: APP_SET_SELECTED_MODEL_ID_ACTION,
        payload: {
          model_id: act.payload.modelId,
        },
      },
      {
        type: APP_GET_AND_SET_CURRENT_PROJECT_ACTION,
        payload: {
          projectId: act.payload.projectId,
        },
      },
      {
        type: APP_GET_MODEL_SETTINGS_ACTION,
        payload: { modelId: act.payload.modelId },
      },
      {
        type: APP_GET_MODEL_BATCHES_ACTION,
        payload: { modelId: act.payload.modelId },
      },
      {
        type: APP_GET_PROJECTS_REQUEST_ACTION,
      },
      {
        type: APP_GET_NUMBER_TOPIC_SEGMENTS_REQUEST,
        payload: { modelId: act.payload.modelId },
      },
    ])
  );
};

export const analysisSettingsEpic = combineEpics(prepareAnalysisSettingsEpic);
