import { Observable, Store } from "redux";
import { ActionsObservable, combineEpics, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { EpicDependencies } from ".";
import {
  APP_GET_BILLING_INFORMATION_ERROR,
  APP_GET_BILLING_INFORMATION_REQUEST,
  APP_SET_CURRENT_CAPACITY_ACTION,
  APP_SET_CURRENT_SUBSCRIPTION_ACTION,
  APP_UI_ADD_TOAST_ACTION,
  DEEPTALK_BILLING_HOST,
} from "../constants";
import ApiKit from "../utils/ApiKit";
import { getCapacityState } from "../dal/settings";

const getBillingInfoEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_GET_BILLING_INFORMATION_REQUEST),
    mergeMap(() =>
      from(getCapacityState()).pipe(
        mergeMap((response) => [
          {
            type: APP_SET_CURRENT_SUBSCRIPTION_ACTION,
            payload: { subscription: response },
          },
        ]),
        catchError((error) =>
          of({
            type: APP_UI_ADD_TOAST_ACTION,
            payload: {
              id: APP_GET_BILLING_INFORMATION_REQUEST,
              type: "error",
              text: "Something went wrong getting your billing information.",
            },
          })
        )
      )
    )
  );
};

export const billingEpic = combineEpics(getBillingInfoEpic);
