import { Observable, Store } from "redux";
import {
  ActionsObservable,
  StateObservable,
  combineEpics,
  ofType,
} from "redux-observable";
import { from } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { ModelType } from "../dal/model.interfaces";
import {
  projectModelsCreateReq,
  createTopicGroupReq,
  getTopicGroupsReq,
  getTopicGroupMembershipsReq,
  createTopicGroupMembershipsReq,
  deleteTopicGroupMembershipsReq,
  getTopicGroupMembershipsByTopicReq,
  deleteTopicGroupReq,
  editTopicGroupReq,
  modelGet,
  modelGetBatches,
  modelGetNTopicSegments,
  modelGetSunburstData,
} from "../dal/model";
import APIKit from "../utils/ApiKit";
import { EpicDependencies } from ".";

import {
  APP_SAVE_MODEL_REQUEST_ACTION,
  APP_SAVE_MODEL_ERROR_ACTION,
  APP_SAVE_MODEL_SUCCESS_ACTION,
  DEEPTALK_API_HOST,
  APP_UI_ADD_TOAST_ACTION,
  APP_WIZARD_CREATE_MODEL_ACTION,
  APP_WIZARD_CREATE_MODEL_SUCCESS_ACTION,
  APP_PROJECTS_CREATE_MODEL_ERROR_ACTION,
  APP_WIZARD_NEXT_STEP_ACTION,
  AnyActionI,
  APP_CLOSE_INTEGRATION_MODAL_ACTION,
  APP_CREATE_INTEGRATION_MODEL_ACTION,
  CreateIntegrationModelAction,
  APP_CREATE_TOPIC_GROUP_REQUEST_ACTION,
  APP_GET_TOPIC_GROUPS_ACTION,
  APP_GET_TOPIC_GROUPS_SUCCESS_ACTION,
  APP_GET_TOPIC_GROUP_MEMBERSHIPS_SUCCESS_ACTION,
  APP_GET_TOPIC_GROUP_MEMBERSHIPS_ACTION,
  APP_CREATE_TOPIC_GROUP_MEMBERSHIPS_ACTION,
  APP_DELETE_TOPIC_GROUP_MEMBERSHIPS_ACTION,
  APP_GET_TOPIC_GROUP_MEMBERSHIPS_BY_GROUP_ACTION,
  APP_GET_TOPIC_GROUP_MEMBERSHIPS_BY_GROUP_SUCCESS_ACTION,
  APP_DELETE_TOPIC_GROUP_ACTION,
  APP_EDIT_TOPIC_GROUP_ACTION,
  APP_PAYMENT_REQUIRED_ACTION,
  APP_GET_METADATA_SUMMARY_REQUEST_ACTION,
  APP_GET_METADATA_SUMMARY_SUCCESS_ACTION,
  APP_SET_METADATA_SUMMARY_ACTION,
  APP_GET_METADATA_SUMMARY_ERROR_ACTION,
  APP_GET_MODEL_SETTINGS_ACTION,
  APP_SET_MODEL_SETTINGS_ACTION,
  APP_GET_MODEL_BATCHES_ACTION,
  APP_SET_MODEL_BATCHES_ACTION,
  APP_GET_NUMBER_TOPIC_SEGMENTS_REQUEST,
  APP_SET_MODEL_TOPIC_SEGMENTS_COUNT,
  APP_SET_CURRENT_MODEL_ACTION,
  APP_WIZARD_CLOSE_MODAL_ACTION,
  APP_GET_SUNBURST_DATA,
  APP_SET_SUNBURST_DATA,
} from "../constants";
import { ActionWithPayload, AppState } from "../reducers/interfaces";
import { getMetadataSummaryReq } from "../dal/metadata";

const saveModelRequest = (projectId: number, data: any) => {
  return APIKit.getInstance().post(
    `${DEEPTALK_API_HOST}/projects/${projectId}/models/`,
    data
  );
};

const editTopicGroup = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_EDIT_TOPIC_GROUP_ACTION),
    mergeMap((action: ActionWithPayload<any>) =>
      from(
        editTopicGroupReq(
          action.payload.model_id,
          action.payload.group_id,
          action.payload.name
        )
      ).pipe(
        mergeMap((response) => [
          {
            type: APP_GET_TOPIC_GROUPS_ACTION,
            payload: { model_id: action.payload.model_id },
          },
        ]),
        catchError((error) =>
          from([
            {
              type: APP_UI_ADD_TOAST_ACTION,
              payload: {
                id: APP_DELETE_TOPIC_GROUP_MEMBERSHIPS_ACTION,
                type: "error",
                text: "Could not delete topic group.",
              },
            },
          ])
        )
      )
    )
  );
};

const deleteTopicGroup = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_DELETE_TOPIC_GROUP_ACTION),
    mergeMap((action: ActionWithPayload<any>) =>
      from(
        deleteTopicGroupReq(action.payload.model_id, action.payload.group_id)
      ).pipe(
        mergeMap((response) => [
          {
            type: APP_GET_TOPIC_GROUPS_ACTION,
            payload: { model_id: action.payload.model_id },
          },
        ]),
        catchError((error) =>
          from([
            {
              type: APP_UI_ADD_TOAST_ACTION,
              payload: {
                id: APP_DELETE_TOPIC_GROUP_MEMBERSHIPS_ACTION,
                type: "error",
                text: "Could not delete topic group.",
              },
            },
          ])
        )
      )
    )
  );
};

const getTopicGroupMembershipsByGroups = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_GET_TOPIC_GROUP_MEMBERSHIPS_BY_GROUP_ACTION),
    mergeMap((action: ActionWithPayload<any>) =>
      from(
        getTopicGroupMembershipsByTopicReq(
          action.payload.model_id,
          action.payload.group_id
        )
      ).pipe(
        mergeMap((response) => [
          {
            type: APP_GET_TOPIC_GROUP_MEMBERSHIPS_BY_GROUP_SUCCESS_ACTION,
            payload: {
              group_id: action.payload.group_id,
              topic_group_memberships: response.data,
            },
          },
        ]),
        catchError((error) =>
          from([
            {
              type: APP_UI_ADD_TOAST_ACTION,
              payload: {
                id: APP_GET_TOPIC_GROUP_MEMBERSHIPS_BY_GROUP_ACTION,
                type: "error",
                text: "There was an error getting the topic groups.",
              },
            },
          ])
        )
      )
    )
  );
};

const deleteTopicGroupMembership = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_DELETE_TOPIC_GROUP_MEMBERSHIPS_ACTION),
    mergeMap((action: ActionWithPayload<any>) =>
      from(
        deleteTopicGroupMembershipsReq(
          action.payload.model_id,
          action.payload.id
        )
      ).pipe(
        mergeMap((response) => [
          {
            type: APP_GET_TOPIC_GROUP_MEMBERSHIPS_ACTION,
            payload: {
              model_id: action.payload.model_id,
              topic_id: action.payload.topic_id,
            },
          },
          {
            type: APP_GET_TOPIC_GROUP_MEMBERSHIPS_BY_GROUP_ACTION,
            payload: {
              model_id: action.payload.model_id,
              group_id: action.payload.group_id,
            },
          },
        ]),
        catchError((error) =>
          from([
            {
              type: APP_UI_ADD_TOAST_ACTION,
              payload: {
                id: APP_DELETE_TOPIC_GROUP_MEMBERSHIPS_ACTION,
                type: "error",
                text: "Could not delete group membership.",
              },
            },
          ])
        )
      )
    )
  );
};

const createTopicGroupMembership = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_CREATE_TOPIC_GROUP_MEMBERSHIPS_ACTION),
    mergeMap((action: ActionWithPayload<any>) =>
      from(
        createTopicGroupMembershipsReq(
          action.payload.model_id,
          action.payload.topic_id,
          action.payload.group_id
        )
      ).pipe(
        mergeMap((response) => [
          {
            type: APP_GET_TOPIC_GROUP_MEMBERSHIPS_ACTION,
            payload: {
              model_id: action.payload.model_id,
              topic_id: action.payload.topic_id,
            },
          },
          {
            type: APP_GET_TOPIC_GROUP_MEMBERSHIPS_BY_GROUP_ACTION,
            payload: {
              model_id: action.payload.model_id,
              group_id: action.payload.group_id,
            },
          },
        ]),
        catchError((error) =>
          from([
            {
              type: APP_UI_ADD_TOAST_ACTION,
              payload: {
                id: APP_CREATE_TOPIC_GROUP_MEMBERSHIPS_ACTION,
                type: "error",
                text: "There was an error creating the group membership.",
              },
            },
          ])
        )
      )
    )
  );
};

const getTopicGroupMembershipsByTopic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_GET_TOPIC_GROUP_MEMBERSHIPS_ACTION),
    mergeMap((action: ActionWithPayload<any>) =>
      from(
        getTopicGroupMembershipsReq(
          action.payload.model_id,
          action.payload.topic_id
        )
      ).pipe(
        mergeMap((response) => [
          {
            type: APP_GET_TOPIC_GROUP_MEMBERSHIPS_SUCCESS_ACTION,
            payload: { topic_group_memberships: response.data },
          },
        ]),
        catchError((error) =>
          from([
            {
              type: APP_UI_ADD_TOAST_ACTION,
              payload: {
                id: APP_GET_TOPIC_GROUP_MEMBERSHIPS_ACTION,
                type: "error",
                text: "There was an error getting the topic groups.",
              },
            },
          ])
        )
      )
    )
  );
};

const getTopicGroups = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_GET_TOPIC_GROUPS_ACTION),
    mergeMap((action: ActionWithPayload<any>) =>
      from(getTopicGroupsReq(action.payload.model_id)).pipe(
        mergeMap((response) => [
          {
            type: APP_GET_TOPIC_GROUPS_SUCCESS_ACTION,
            payload: {
              model_id: action.payload.model_id,
              topic_groups: response.data,
            },
          },
        ]),
        catchError((error) =>
          from([
            {
              type: APP_UI_ADD_TOAST_ACTION,
              payload: {
                id: APP_GET_TOPIC_GROUPS_ACTION,
                type: "error",
                text: "There was an error getting the topic groups.",
              },
            },
          ])
        )
      )
    )
  );
};

const createTopicGroup = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_CREATE_TOPIC_GROUP_REQUEST_ACTION),
    mergeMap((action: ActionWithPayload<any>) =>
      from(
        createTopicGroupReq(action.payload.modelId, action.payload.groupName)
      ).pipe(
        mergeMap((response) => [
          {
            type: APP_GET_TOPIC_GROUPS_ACTION,
            payload: { model_id: action.payload.model_id },
          },
        ]),
        catchError((error) =>
          from([
            {
              type: APP_UI_ADD_TOAST_ACTION,
              payload: {
                id: APP_CREATE_TOPIC_GROUP_REQUEST_ACTION,
                type: "error",
                text: "There was an error creating the topic group.",
              },
            },
          ])
        )
      )
    )
  );
};

const saveModel = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_SAVE_MODEL_REQUEST_ACTION),
    mergeMap((action: ActionWithPayload<any>) =>
      from(
        saveModelRequest(action.payload.projectId, action.payload.model)
      ).pipe(
        mergeMap((response) => [
          {
            type: APP_SAVE_MODEL_SUCCESS_ACTION,
            payload: {
              projectId: action.payload.projectId,
              model: response.data,
            },
          },
        ]),
        catchError((error) => {
          if (error.response.status === 402) {
            return from([
              {
                type: APP_UI_ADD_TOAST_ACTION,
                payload: {
                  id: APP_PAYMENT_REQUIRED_ACTION,
                  type: "payment",
                  text: "To create more Models, please subscribe to a higher plan.",
                },
              },
            ]);
          } else {
            return from([
              {
                type: APP_UI_ADD_TOAST_ACTION,
                payload: {
                  id: APP_SAVE_MODEL_REQUEST_ACTION,
                  type: "error",
                  text: "There was an error in saving the model.",
                },
              },
              { type: APP_SAVE_MODEL_ERROR_ACTION, payload: error.message },
            ]);
          }
        })
      )
    )
  );
};

const createProjectModelEpic = (
  action$: ActionsObservable<AnyActionI>,
  state$: StateObservable<AppState>
) => {
  return action$.pipe(
    ofType(APP_WIZARD_CREATE_MODEL_ACTION),
    mergeMap((a) => {
      const state = state$.value.modelWizard;
      return from(
        projectModelsCreateReq(state.projectId as number, {
          name: state.model?.model_name as string,
          model_type: state.model?.model_type as ModelType,
          description: state.model?.description,
          lang: state.model?.lang,
          industry: state.model?.industry,
          use_case: state.model?.use_case,
          content_type: state.model?.data_type,
        })
      ).pipe(
        mergeMap((res) => [
          {
            type: APP_WIZARD_CREATE_MODEL_SUCCESS_ACTION,
            payload: { projectId: state.projectId, model: res.data },
          } as AnyActionI,
          ["DEC", "DET"].includes(state.model?.model_type ?? "")
            ? { type: APP_WIZARD_NEXT_STEP_ACTION }
            : { type: APP_WIZARD_CLOSE_MODAL_ACTION },
        ]),
        catchError((error) => {
          if (error.response.status === 402) {
            return from([
              {
                type: APP_UI_ADD_TOAST_ACTION,
                payload: {
                  id: APP_PAYMENT_REQUIRED_ACTION,
                  type: "payment",
                  text: "To create more Models, please upgrade your account.",
                },
              },
              {
                type: APP_PROJECTS_CREATE_MODEL_ERROR_ACTION,
                payload: error.message,
              },
            ]);
          } else {
            return from([
              {
                type: APP_UI_ADD_TOAST_ACTION,
                payload: {
                  id: APP_SAVE_MODEL_REQUEST_ACTION,
                  type: "error",
                  text: "Please ensure that model names are unique within a project",
                },
              },
              {
                type: APP_PROJECTS_CREATE_MODEL_ERROR_ACTION,
                payload: error.message,
              },
            ]);
          }
        })
      );
    })
  );
};

const createIntegrationModelEpic = (
  action$: ActionsObservable<AnyActionI>,
  state$: StateObservable<AppState>
) => {
  return action$.pipe(
    ofType(APP_CREATE_INTEGRATION_MODEL_ACTION),
    mergeMap((a) => {
      const action = a as CreateIntegrationModelAction;
      return from(
        projectModelsCreateReq(action.payload.projectId as number, {
          name: action.payload.name,
          model_type: "TD" as ModelType,
          status: "data-collection",
        })
      ).pipe(mergeMap((res) => [{ type: APP_CLOSE_INTEGRATION_MODAL_ACTION }]));
    })
  );
};

const getMetadataSummary = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_GET_METADATA_SUMMARY_REQUEST_ACTION),
    mergeMap((action: ActionWithPayload<any>) =>
      from(getMetadataSummaryReq(action.payload.modelId)).pipe(
        mergeMap((res) => [
          {
            type: APP_GET_METADATA_SUMMARY_SUCCESS_ACTION,
            payload: {},
          },
          {
            type: APP_SET_METADATA_SUMMARY_ACTION,
            payload: {
              metadata_summary: res.data,
            },
          },
        ]),
        catchError((error) =>
          from([
            {
              type: APP_GET_METADATA_SUMMARY_ERROR_ACTION,
              payload: {},
            },
          ])
        )
      )
    )
  );
};

const getModelSettings = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_GET_MODEL_SETTINGS_ACTION),
    mergeMap((action: ActionWithPayload<any>) =>
      from(modelGet(action.payload.modelId)).pipe(
        mergeMap((res) => [
          {
            type: APP_SET_MODEL_SETTINGS_ACTION,
            payload: {
              settings: res.data.settings,
              is_searchable: res.data.is_searcheable,
            },
          },
          {
            type: APP_SET_CURRENT_MODEL_ACTION,
            payload: {
              model: res.data,
            },
          },
        ]),
        catchError((error) =>
          from([
            {
              type: APP_SET_MODEL_SETTINGS_ACTION,
              payload: {
                settings: {},
                is_searchable: false,
              },
            },
          ])
        )
      )
    )
  );
};

const getModelBatches = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_GET_MODEL_BATCHES_ACTION),
    mergeMap((action: ActionWithPayload<any>) =>
      from(modelGetBatches(action.payload.modelId)).pipe(
        mergeMap((res) => [
          {
            type: APP_SET_MODEL_BATCHES_ACTION,
            payload: {
              batches: res.data,
            },
          },
        ]),
        catchError((error) =>
          from([
            {
              type: APP_SET_MODEL_BATCHES_ACTION,
              payload: {
                batches: [],
              },
            },
          ])
        )
      )
    )
  );
};

const getNumberTopicSegments = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_GET_NUMBER_TOPIC_SEGMENTS_REQUEST),
    mergeMap((action: ActionWithPayload<any>) =>
      from(modelGetNTopicSegments(action.payload.modelId)).pipe(
        mergeMap((res) => [
          {
            type: APP_SET_MODEL_TOPIC_SEGMENTS_COUNT,
            payload: {
              n_topic_segments: res.data.count,
            },
          },
        ]),
        catchError((error) =>
          from([
            {
              type: APP_SET_MODEL_TOPIC_SEGMENTS_COUNT,
              payload: {
                n_topic_segments: 0,
              },
            },
          ])
        )
      )
    )
  );
};

const getSunburstDataEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_GET_SUNBURST_DATA),
    mergeMap((action: ActionWithPayload<any>) =>
      from(
        modelGetSunburstData(
          action.payload.modelId,
          action.payload.min_topic_size
        )
      ).pipe(
        mergeMap((res) => [
          {
            type: APP_SET_SUNBURST_DATA,
            payload: {
              sunburst: res.data,
            },
          },
        ]),
        catchError((error) =>
          from([
            {
              type: APP_SET_SUNBURST_DATA,
              payload: {
                sunburst: {},
              },
            },
          ])
        )
      )
    )
  );
};

export const modelEpic = combineEpics(
  saveModel,
  createProjectModelEpic,
  createIntegrationModelEpic,
  createTopicGroup,
  getTopicGroups,
  getTopicGroupMembershipsByTopic,
  createTopicGroupMembership,
  deleteTopicGroupMembership,
  getTopicGroupMembershipsByGroups,
  deleteTopicGroup,
  editTopicGroup,
  getMetadataSummary,
  getModelSettings,
  getModelBatches,
  getNumberTopicSegments,
  getSunburstDataEpic
);
