import { Observable, Store } from "redux";
import { ActionsObservable, combineEpics, ofType } from "redux-observable";
import { forkJoin, from, of } from "rxjs";
import {
  catchError,
  concatMap,
  ignoreElements,
  map,
  mergeMap,
  tap,
} from "rxjs/operators";
import APIKit from "../utils/ApiKit";
import { EpicDependencies } from ".";

import {
  APP_GET_PROJECTS_REQUEST_ACTION,
  APP_GET_PROJECTS_ERROR_ACTION,
  APP_GET_PROJECTS_SUCCESS_ACTION,
  APP_SAVE_PROJECT_REQUEST_ACTION,
  APP_SAVE_PROJECT_ERROR_ACTION,
  APP_SAVE_PROJECT_SUCCESS_ACTION,
  DEEPTALK_API_HOST,
  APP_UI_ADD_TOAST_ACTION,
  APP_EDIT_PROJECT_REQUEST_ACTION,
  APP_SET_ALL_MODELS_ACTION,
  APP_GATHER_ALL_MODELS_ACTION,
  APP_PAYMENT_REQUIRED_ACTION,
  APP_GET_AND_SET_CURRENT_PROJECT_ACTION,
  APP_SET_SELECTED_PROJECT_ACTION,
  APP_SET_CURRENT_PROJECT_MODELS_ACTION,
} from "../constants";
import { ActionWithPayload } from "../reducers/interfaces";
import { ProjectModelCreateRes } from "../dal";

const getAllModelsRequest = () => {
  return APIKit.getInstance().get(`${DEEPTALK_API_HOST}/models/?all=true`);
};

const getProjectRequest = (projectId: number) => {
  return APIKit.getInstance().get(`${DEEPTALK_API_HOST}/projects/${projectId}`);
};

const getProjectAnalysesRequest = (projectId: number) => {
  return APIKit.getInstance().get(
    `${DEEPTALK_API_HOST}/projects/${projectId}/models/`
  );
};

const getProjectsRequest = () => {
  return APIKit.getInstance().get(`${DEEPTALK_API_HOST}/projects/?all=true`);
};

const saveProjectRequest = (data: any) => {
  return APIKit.getInstance().post(`${DEEPTALK_API_HOST}/projects/`, data);
};

const editProjectRequest = (project_id: number, data: any) => {
  return APIKit.getInstance().patch<ProjectModelCreateRes>(
    `${DEEPTALK_API_HOST}/projects/${project_id}/`,
    data
  );
};

const getProjectsEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_GET_PROJECTS_REQUEST_ACTION),
    mergeMap(() =>
      from(getProjectsRequest()).pipe(
        map((response) => ({
          type: APP_GET_PROJECTS_SUCCESS_ACTION,
          payload: response.data,
        })),
        catchError((error) =>
          of({
            type: APP_GET_PROJECTS_ERROR_ACTION,
            payload: error.message,
          })
        )
      )
    )
  );
};

const getAndSetCurrentProjectEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_GET_AND_SET_CURRENT_PROJECT_ACTION),
    mergeMap((action: ActionWithPayload<any>) =>
      forkJoin({
        project: from(getProjectRequest(action.payload.projectId)),
        analyses: from(getProjectAnalysesRequest(action.payload.projectId)),
      }).pipe(
        map(({ project, analyses }) => ({
          type: APP_SET_SELECTED_PROJECT_ACTION,
          payload: {
            ...project.data,
            models: analyses.data,
          },
        })),
        catchError((error) =>
          of({
            type: APP_GET_PROJECTS_ERROR_ACTION,
            payload: error.message,
          })
        )
      )
    )
  );
};

const saveProjectEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_SAVE_PROJECT_REQUEST_ACTION),
    mergeMap((action: ActionWithPayload<any>) =>
      from(saveProjectRequest(action.payload)).pipe(
        mergeMap((response) => [
          { type: APP_SAVE_PROJECT_SUCCESS_ACTION, payload: response.data },
          { type: APP_GET_PROJECTS_REQUEST_ACTION },
        ]),
        catchError((error) => {
          if (error.response.status === 402) {
            return from([
              {
                type: APP_UI_ADD_TOAST_ACTION,
                payload: {
                  id: APP_PAYMENT_REQUIRED_ACTION,
                  type: "payment",
                  text: "To create more Projects, please subscribe to a higher plan.",
                  duration: 7500,
                },
              },
              { type: APP_SAVE_PROJECT_ERROR_ACTION, payload: error.message },
            ]);
          } else {
            return from([
              {
                type: APP_UI_ADD_TOAST_ACTION,
                payload: {
                  id: APP_SAVE_PROJECT_REQUEST_ACTION,
                  type: "error",
                  text: "Ensure that the project name is unique.",
                  duration: 7500,
                },
              },
              { type: APP_SAVE_PROJECT_ERROR_ACTION, payload: error.message },
            ]);
          }
        })
      )
    )
  );
};

const editProjectEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_EDIT_PROJECT_REQUEST_ACTION),
    mergeMap((action) => {
      return from(
        editProjectRequest(action.payload.projectId, action.payload)
      ).pipe(
        mergeMap((response) => [{ type: APP_GET_PROJECTS_REQUEST_ACTION }]),
        catchError((error) => {
          if (error.response.status === 405) {
            return from([
              {
                type: APP_UI_ADD_TOAST_ACTION,
                payload: {
                  id: APP_EDIT_PROJECT_REQUEST_ACTION,
                  type: "error",
                  text: "Unfortunately you cannot edit this project.",
                },
              },
            ]);
          }
          return from([
            {
              type: APP_UI_ADD_TOAST_ACTION,
              payload: {
                id: APP_EDIT_PROJECT_REQUEST_ACTION,
                type: "error",
                text: "Ensure that the project name is unique.",
              },
            },
          ]);
        })
      );
    })
  );
};

const saveProjectRedirect = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_SAVE_PROJECT_SUCCESS_ACTION),
    tap(
      (action) => {}
      // dependencies.history.push(`/projects/${action.payload.id}`)
    ),
    ignoreElements()
  );
};

const gatherAllModelsEpic = (
  action$: ActionsObservable<any>,
  store$: Observable<Store>,
  dependencies: EpicDependencies
): any => {
  return action$.pipe(
    ofType(APP_GATHER_ALL_MODELS_ACTION),
    mergeMap(() =>
      from(getAllModelsRequest()).pipe(
        map((response) => ({
          type: APP_SET_ALL_MODELS_ACTION,
          payload: response.data,
        })),
        catchError((error) =>
          of({
            type: APP_GET_PROJECTS_ERROR_ACTION,
            payload: error.message,
          })
        )
      )
    )
  );
};

export const projectEpic = combineEpics(
  saveProjectEpic,
  editProjectEpic,
  getProjectsEpic,
  saveProjectRedirect,
  gatherAllModelsEpic,
  getAndSetCurrentProjectEpic
  // getAndSetCurrentProjectsAnalyses
);
