import { ActionsObservable, combineEpics, ofType, StateObservable } from "redux-observable";
import { from } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { AnyActionI, APP_SET_ACTIVATED_INTEGRATIONS, APP_UPDATE_ACTIVATED_INTEGRATIONS, UpdatedActivatedIntegrations } from "../constants";
import { getActivatedIntegrationsReq } from "../dal/integrations";
import { AppState } from "../reducers/interfaces";

const updateActivatedIntegrationsEpic = (
    action$: ActionsObservable<AnyActionI>,
    state$: StateObservable<AppState>,
) => {
    return action$.pipe(
        ofType(APP_UPDATE_ACTIVATED_INTEGRATIONS),
        mergeMap((act) => {
            const accId = state$.value.auth.currentUser?.person.account as string;
            return from(getActivatedIntegrationsReq(accId))
            .pipe(
                mergeMap((res) => [
                    {type: APP_SET_ACTIVATED_INTEGRATIONS, payload: res.data}
                ])
            )
        })
    )
}

export const integrationEpic = combineEpics(
    updateActivatedIntegrationsEpic,
)