import { DEEPTALK_API_HOST } from "../constants";
import api from "../utils/ApiKit";
import { AxiosUploadProgressEvent } from "./common.interfaces";
import {
  Project,
  ProjectFetchFilesRes,
  ProjectUploadFilePayload,
  ProjectUploadFileRes,
} from "./projects.interfaces";

export const projectFetchFilesReq = (projectId: number) =>
  api
    .getInstance()
    .get<ProjectFetchFilesRes>(
      `${DEEPTALK_API_HOST}/projects/${projectId}/uploaded_files/`
    );

export const projectUploadFileReq = (
  projectId: number,
  file: ProjectUploadFilePayload,
  onUploadProgress: (e: AxiosUploadProgressEvent) => void = () => {}
) => {
  const formData = new FormData();
  formData.append("csv_file", file);
  return api
    .getInstance()
    .post<ProjectUploadFileRes>(
      `${DEEPTALK_API_HOST}/projects/${projectId}/uploaded_files/`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress,
      }
    );
};

export const projectCreateReq = (name: string, description: string) => {
  return api
    .getInstance()
    .post<Project>(`${DEEPTALK_API_HOST}/projects/`, { name, description });
};
