import Keycloak from 'keycloak-js';

const configure = () => {
   const keycloakConfig = {
      url: 'https://keycloak.deep-talk.ai/auth',
      realm: process.env.REACT_APP_KEYCLOAK_REALM,
      clientId: 'frontend-admin'
   }
   const instance = new Keycloak(keycloakConfig);

   return instance;
}

export {
   configure
};
