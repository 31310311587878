import { DEEPTALK_API_HOST } from "../constants";
import ApiKit from "../utils/ApiKit";

export type PretrainedDeeper = {
  id: string;
  title: string;
  description: string;
  metadata: PretrainedDeeperMetadata;
  ppl_name: string;
  name: string;
  default?: boolean;
  icon?: string;
  weight: number;
};

type PretrainedDeeperMetadata = {
  labels: PretrainedDeeperLabel[];
  icon: string;
};

type PretrainedDeeperLabel = {
  name: string;
  description: string;
  icon?: string;
};

export const execCreatingDeeperMembershipRequest = async (
  modelId = 0,
  identifier = ""
) => {
  return await ApiKit.getInstance().post(
    `${DEEPTALK_API_HOST}/models/${modelId}/categories/detect/`,
    {
      deeper_uuid: identifier,
    }
  );
};

export const execDeleteDeeperMembershipRequest = async (
  modelId = 0,
  id = ""
) => {
  return await ApiKit.getInstance().delete(
    `${DEEPTALK_API_HOST}/models/${modelId}/categories/detect/${id}/`
  );
};

export const execPatchDeeperMembershipRequest = async (
  modelId = 0,
  id = "",
  pl = {}
) => {
  return await ApiKit.getInstance().patch(
    `${DEEPTALK_API_HOST}/models/${modelId}/categories/detect/${id}/`,
    pl
  );
};

const execRequest = async () => {
  return await ApiKit.getInstance().get<PretrainedDeeper[]>(
    `${DEEPTALK_API_HOST}/models/deepers/`
  );
};

export const execGetPretrainedDeepers = async (payload: {}) => {
  const res = await execRequest();
  return res.data;
};
