import {
  AnyReportActionI,
  APP_SET_INSIGHT_ACTION,
  APP_SET_REPORT_DETAIL_ACTION,
  APP_SET_REPORTS_ACTION,
} from "../constants";
import { Insight, Report, ReportDetail } from "../dal/reports";

export type AppReportsState = {
  list: Report[];
  detail?: ReportDetail;
  insight?: Insight;
};

const initialState: AppReportsState = {
  list: [],
  detail: undefined,
  insight: undefined,
};

export const reportReducer = (
  state = initialState,
  action: AnyReportActionI
) => {
  switch (action.type) {
    case APP_SET_REPORTS_ACTION: {
      const reports = action.payload;
      return {
        ...state,
        list: reports,
      } as AppReportsState;
    }

    case APP_SET_REPORT_DETAIL_ACTION: {
      const report_detail = action.payload.report;
      return {
        ...state,
        detail: report_detail,
      } as AppReportsState;
    }

    case APP_SET_INSIGHT_ACTION: {
      return {
        ...state,
        insight: action.payload.insight,
      } as AppReportsState;
    }

    default:
      return state as AppReportsState;
  }
};
