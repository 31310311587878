import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import { IntlProvider } from "react-intl";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Provider } from "react-redux";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { IntercomProvider } from "react-use-intercom";

import locale_en from "@deep-talk/shared/src/translations/en.json";
import locale_es from "@deep-talk/shared/src/translations/es.json";
import locale_de from "@deep-talk/shared/src/translations/de.json";

import "./polyfill";

// Import our main App and the service worker
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as keycloak from "./keycloak";

// Import the store
import store from "./store";

// Core-ui icons import
import { icons } from "./assets/icons";
import ApiKit from "@deep-talk/shared/src/utils/ApiKit";
import {
  APP_LOGIN_SUCCESS_ACTION,
  APP_LOGOUT_ACTION,
  APP_WEBSOCKET_INIT_ACTION,
  ENVIRONMENT,
  SENTRY_URL,
} from "@deep-talk/shared/src/constants";

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
const keyclockInstance = keycloak.configure();

React.icons = icons;

const data = {
  en: locale_en,
  es: locale_es,
  de: locale_de,
};

const language = navigator.language.split(/[-_]/)[0];

const eventHandler = (store) => (event, error) => {
  if (event === "onTokenExpired") {
    try {
      // keyclockInstance.updateToken(50);
    } catch (error) {
      keyclockInstance.logout();
      store.dispatch({
        type: APP_LOGOUT_ACTION,
      });
    }
  }
  if (event === "onAuthSuccess") {
    console.log(
      "keyclockInstance.resourceAccess",
      keyclockInstance.resourceAccess
    );
    const action = {
      type: APP_LOGIN_SUCCESS_ACTION,
      payload: {
        person: {
          name: keyclockInstance.idTokenParsed.name,
          id: keyclockInstance.idTokenParsed.sub,
          account: keyclockInstance.idTokenParsed.account_id,
          email: keyclockInstance.idTokenParsed.email,
          given_name: keyclockInstance.idTokenParsed.given_name,
          family_name: keyclockInstance.idTokenParsed.family_name,
          roles:
            (Object.keys(keyclockInstance.resourceAccess).includes(
              "frontend-admin"
            )
              ? keyclockInstance.resourceAccess["frontend-admin"]
              : { roles: [] }
            ).roles ?? [],
          project_ids: keyclockInstance.idTokenParsed.project_ids ?? "",
        },
      },
    };
    ApiKit.create({
      headers: {
        Authorization: `Bearer ${keyclockInstance.token}`,
      },
    }).interceptors.request.use((config) => {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${keyclockInstance.token}`,
        },
      };
    });

    store.dispatch(action);
    store.dispatch({
      type: APP_WEBSOCKET_INIT_ACTION,
      payload: {
        userId: keyclockInstance.idTokenParsed.account_id,
        token: keyclockInstance.token,
      },
    });
  }
};

const tokenLogger = (tokens) => {
  console.log("tokens", tokens);
};

Sentry.init({
  dsn: SENTRY_URL,
  integrations: [new Integrations.BrowserTracing()],
  environment: ENVIRONMENT,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Provider store={store}>
    <ReactKeycloakProvider
      authClient={keyclockInstance}
      onEvent={eventHandler(store)}
      onTokens={tokenLogger}
    >
      <IntlProvider locale="es" defaultLocale="es" messages={data[language]}>
        <IntercomProvider appId={INTERCOM_APP_ID}>
          <App />
        </IntercomProvider>
      </IntlProvider>
    </ReactKeycloakProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
