import { AnyApiKeyAction, APP_SET_API_KEY_ACTION } from "../constants";

export type AppApiKeyState = {
  apiKey: string | undefined;
  exists: boolean | undefined;
  canCreate: boolean;
};

const initialState: AppApiKeyState = {
  apiKey: undefined,
  exists: undefined,
  canCreate: false,
};

export const apiKeyReducer = (
  state = initialState,
  action: AnyApiKeyAction
) => {
  switch (action.type) {
    case APP_SET_API_KEY_ACTION: {
      if (action.payload === undefined) {
        return {
          ...state,
          apiKey: undefined,
          exists: false,
          canCreate: false,
        };
      }
      return {
        ...state,
        apiKey: action.payload.apikey,
        exists: action.payload.apikey ? true : false,
        canCreate: action.payload.create_api_key,
      };
    }
    default:
      return state;
  }
};
