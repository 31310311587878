import {
  APP_UI_ADD_TOAST_ACTION,
  APP_UI_REMOVE_TOAST_ACTION,
} from "../constants";

type Toast = {
  id: string;
  type: "success" | "error" | "info" | "payment";
  text: string;
  duration: number | undefined;
  link: string | undefined;
  position: "top-center" | "top-right";
};

export type ToastsState = Toast[];
type ToastAction = {
  type: string;
  payload: Toast;
};

const toastsReducer = (state: ToastsState = [], action: ToastAction) => {
  const { payload, type } = action;

  switch (type) {
    case APP_UI_ADD_TOAST_ACTION:
      return [payload, ...state];

    case APP_UI_REMOVE_TOAST_ACTION:
      return state.filter((toast) => toast.id !== payload.id);

    default:
      return state;
  }
};

export { toastsReducer };
