import { AnyActionI, APP_HIDE_INTRO_VIDEO_MODAL, APP_SHOW_INTRO_VIDEO_MODAL } from "../constants"

type introVideoState = {
    modalShown: boolean
    videoUrl: string
}


const initialState: introVideoState = {
    modalShown: false,
    videoUrl: ""
}

export const introVideoReducer = (state: introVideoState = initialState, action: AnyActionI) => {
    switch (action.type) {
        case APP_SHOW_INTRO_VIDEO_MODAL:
            return {
                modalShown: true,
                videoUrl: action.payload.url,
            };

        case APP_HIDE_INTRO_VIDEO_MODAL:
            return {
                modalShown: false,
                videoUrl: "",
            };

        default:
            return state;
    }
}