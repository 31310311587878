export const DEEPTALK_API_HOST = process.env.REACT_APP_DEEPTALK_API_HOST;
export const DEEPER_API_HOST = process.env.REACT_APP_DEEPER_API_HOST;
export const DEEPTALK_BILLING_HOST = process.env.REACT_APP_BILLING_API_HOST;
export const DEEPTALK_WEBSOCKET_HOST =
  process.env.REACT_APP_DEEPTALK_WEBSOCKET_HOST;
export const DEEPTALK_INTEGRATION_BASE_URL =
  process.env.REACT_APP_INTEGRATION_BASE_URL;
export const DEEPTALK_INTEGRATION_TRAINING_URL =
  process.env.REACT_APP_INTEGRATION_TRAINING_URL;
export const APPSUMO_REDIRECT_URI = process.env.REACT_APP_APPSUMO_REDIRECT_URI;
export const APPSUMO_REGISTRATION_URL =
  process.env.REACT_APP_APPSUMO_USER_REGISTRATION;
export const TRAINING_INITIALISATION_URL =
  process.env.REACT_APP_TRAINING_INITIALISATION_URL;
export const MODEL_ID_TO_DISPLAY_SENTIMENT_RESULTS =
  process.env.REACT_APP_MODEL_ID_TO_DISPLAY_SENTIMENT_RESULTS;
export const API_KEY_GENERATOR_HOST =
  process.env.REACT_APP_API_KEY_GENERATOR_HOST;
export const API_DISTRIBUTOR = process.env.REACT_APP_API_DISTRIBUTOR;
export const API_DISTRIBUTOR_LL = process.env.REACT_APP_API_DISTRIBUTOR_LL;
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const SENTRY_URL = process.env.REACT_APP_SENTRY_URL;
export const SYNC_URL = process.env.REACT_APP_SYNC_URL;
export const EMPTY_FIELD = "n/a";
export const DEMO_DEEPER_UUID = process.env.REACT_APP_DEMO_DEEPER;
export const KB_SERVICE_ACCOUNT_EMAIL =
  process.env.REACT_APP_KB_SERVICE_ACCOUNT_EMAIL;
