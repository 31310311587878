import { combineReducers } from "redux";

import { authReducer } from "./auth";
import { uiReducer } from "./ui";
import { projectReducer } from "./project";
import { modelResultsReducer } from "./model_results";
import { toastsReducer } from "./toasts";
import { modelWizardReducer } from "./model-training-wizard";
import { accountSettingReducer } from "./account-setting";
import { integrationsReducer } from "./integrations";
import { trendReducer } from "./trends";
import { introVideoReducer } from "./intro-videos";
import { deeperReducer } from "./deepers";
import { apiKeyReducer } from "./api";
import { exporterReducer } from "./exporter";
import { reportReducer } from "./reports";
import { aiModulesReducer } from "./ai_modules";

const createAppReducer = () =>
  combineReducers({
    auth: authReducer,
    ui: uiReducer,
    projects: projectReducer,
    modelResults: modelResultsReducer,
    toasts: toastsReducer,
    modelWizard: modelWizardReducer,
    account: accountSettingReducer,
    integrations: integrationsReducer,
    trends: trendReducer,
    introVideo: introVideoReducer,
    deepers: deeperReducer,
    api: apiKeyReducer,
    exports: exporterReducer,
    reports: reportReducer,
    aiModules: aiModulesReducer,
  });

export { createAppReducer };
